import { GridCellProps } from "@progress/kendo-react-grid";
import { useCallback, useMemo } from "react";
import { longStringEllipsis } from "utils";
import "./TemplateHistoryExpandableTextCell.scss";

interface ExpandableTextCellProps {
    props: GridCellProps,
    fieldName: string,
    fieldDisplayName?: string,
    autoEllipsis?: boolean,
    stringFormatter?: (x: string) => string,
    onClick: (title: string, fullText: string) => void
}

export default function TemplateHistoryExpandableTextCell(cellProps: ExpandableTextCellProps) {
    const { onClick, props, fieldName, stringFormatter, autoEllipsis, fieldDisplayName } = cellProps,
          rawString: string = props.dataItem[fieldName] ?? "",
          formatted = stringFormatter && stringFormatter(rawString),
          fieldValue = formatted || rawString;

    const fieldListLength: number = useMemo(() => {
        const chars = fieldValue.split("");
        return 1 + chars.filter(x => x === ",").length;
    }, [fieldValue]);

    const truncatedText = useMemo(() => {
        return `${longStringEllipsis(fieldValue, 27)}` + 
                `${fieldListLength > 1 ? `+${fieldListLength - 1}` : ""}`;
    }, [fieldValue, fieldListLength]);

    //console.log(`fieldValue: ${fieldValue}, length: ${fieldListLength}`);

    const clickHandler = useCallback(() => {
        if (props.dataItem[fieldName])
            onClick(fieldDisplayName || fieldName, fieldValue);
    }, [onClick, props.dataItem, fieldName, fieldValue, fieldDisplayName]);
    
    return (
        <td>
            <div className={"historyExpandableTextCell"} onClick={clickHandler}>
                <span>
                    {autoEllipsis ? truncatedText : fieldValue}
                </span>
            </div>
        </td>
    )
}