import { ClientValidationError, ClientValidationErrorType } from "errors";
import { ValidationService, 
         SupplierPricingExcelTemplate, 
         SupplierPricingExcelDataRow,
         ClientValidationResult, 
         FloatItemPricingExcelTemplate,
         FloatItemPricingExcelDataRow,
         FloatProductLinePricingExcelTemplate,
         FloatProductLinePricingExcelDataRow,
         SpecialFixedPricingExcelTemplate,
         SpecialFixedPricingExcelDataRow } from "types";
import { useSupplierPricingTemplateValidationService, 
         useFloatItemPricingTemplateValidationService, 
         useFloatProductLinePricingTemplateValidationService,
         useSpecialFixedPricingTemplateValidationService,
         useExcelTemplateTypeCheckService } from 'services';
import { ExcelTemplateTypes } from "enums";

interface TemplateTypeMatcher {
    checkTemplateMatch: (data: any[], type: ExcelTemplateTypes) => void,
    testingExports?: any | undefined | null
}

interface ServiceCollection {
    supplierTemplate: ValidationService,
    floatItemTemplate: ValidationService,
    floatProductLineTemplate: ValidationService,
    specialFixedTemplate: ValidationService,
    typeChecker: TemplateTypeMatcher
}

//Processes for validating and submitting each template type to the validation API
export function useExcelTemplateSubmitService(id: string, fileName: string, changeNotes: string, postValidationCallback?: (args: any[]) => void) {

    const validationServices: ServiceCollection = {
        supplierTemplate: useSupplierPricingTemplateValidationService(id, fileName, changeNotes, postValidationCallback),
        floatItemTemplate: useFloatItemPricingTemplateValidationService(id, fileName, changeNotes, postValidationCallback),
        floatProductLineTemplate: useFloatProductLinePricingTemplateValidationService(id, fileName, changeNotes, postValidationCallback),
        specialFixedTemplate: useSpecialFixedPricingTemplateValidationService(id, fileName, changeNotes, postValidationCallback),
        typeChecker: useExcelTemplateTypeCheckService()
    };

    //Supplier Pricing Template
    const submitSupplierTemplate = async (data: string, numHeaderRows: number, maxBrackets: number, cosmosId: string, changeNotes: string, allowIncompleteProductLines: boolean) => {
        const validationService = validationServices.supplierTemplate;
        let raw = JSON.parse(data) as any[];

        id = id == null ? "" : id;

        validationServices.typeChecker.checkTemplateMatch(raw, ExcelTemplateTypes.Supplier);
        const excelModel: SupplierPricingExcelTemplate[] = validationService.standardizeExcelPropertyNameFormat(raw),
              clientValidated: ClientValidationResult = await validationService.doClientSideValidation(excelModel, numHeaderRows, maxBrackets);

        if (clientValidated.errorCount === 0) {
            const response = await validationService.postToValidationApi(clientValidated.requestData as SupplierPricingExcelDataRow[], cosmosId, changeNotes, allowIncompleteProductLines);
            return response;
        } else {
            throw new ClientValidationError(ClientValidationErrorType.Aggregate, 
                                            "One or more errors occured during validation.", 
                                            clientValidated.errorList);
        }
    };

    //Special Float Item Template
    const submitFloatItemTemplate = async (data: string, numHeaderRows: number) => {
        const validationService = validationServices.floatItemTemplate;
        let raw = JSON.parse(data) as any[];
        
        validationServices.typeChecker.checkTemplateMatch(raw, ExcelTemplateTypes.FloatItem);
        const excelModel: FloatItemPricingExcelTemplate[] = validationService.standardizeExcelPropertyNameFormat(raw),
              clientValidated: ClientValidationResult = await validationService.doClientSideValidation(excelModel, numHeaderRows);
        if (clientValidated.errorCount === 0) {
            const response = await validationService.postToValidationApi(clientValidated.requestData as FloatItemPricingExcelDataRow[], "", "");
            return response;
        } else {
            throw new ClientValidationError(ClientValidationErrorType.Aggregate, 
                                            "One or more errors occured during validation.", 
                                            clientValidated.errorList);
        }
    };

    //Special Float Product Line Template
    const submitFloatProductLineTemplate = async (data: string, numHeaderRows: number) => {
        const validationService = validationServices.floatProductLineTemplate;
        let raw = JSON.parse(data) as any[];
        
        validationServices.typeChecker.checkTemplateMatch(raw, ExcelTemplateTypes.FloatProductLine);
        const excelModel: FloatProductLinePricingExcelTemplate[] = validationService.standardizeExcelPropertyNameFormat(raw),
              clientValidated: ClientValidationResult = await validationService.doClientSideValidation(excelModel, numHeaderRows);
        if (clientValidated.errorCount === 0) {
            const response = await validationService.postToValidationApi(clientValidated.requestData as FloatProductLinePricingExcelDataRow[], "", "");
            return response;
        } else {
            throw new ClientValidationError(ClientValidationErrorType.Aggregate, 
                                            "One or more errors occured during validation.", 
                                            clientValidated.errorList);
        }
    };

    //Special Fixed Price Template
    const submitSpecialFixedTemplate = async (data: string, numHeaderRows: number) => {
        const validationService = validationServices.specialFixedTemplate;
        let raw = JSON.parse(data) as any[];

        validationServices.typeChecker.checkTemplateMatch(raw, ExcelTemplateTypes.Fixed);
        const excelModel: SpecialFixedPricingExcelTemplate[] = validationService.standardizeExcelPropertyNameFormat(raw),
              clientValidated: ClientValidationResult = await validationService.doClientSideValidation(excelModel, numHeaderRows);
        if (clientValidated.errorCount === 0) {
            const response = await validationService.postToValidationApi(clientValidated.requestData as SpecialFixedPricingExcelDataRow[], "", "");
            return response;
        } else {
            throw new ClientValidationError(ClientValidationErrorType.Aggregate,
                "One or more errors occured during validation.",
                clientValidated.errorList);
        }
    };

    const parseApiResultRowErrors = (apiErrors: string[]) => {
        const extractRow = /data row (\d+)/i;
        for (const rowError in apiErrors) {
            const rowNumber = extractRow.exec(rowError);
            if (rowNumber) {
                
            }
            extractRow.lastIndex = 0;
        }
    };

    return {
        parseApiResultRowErrors,
        submitSupplierTemplate,
        submitFloatItemTemplate,
        submitFloatProductLineTemplate,
        submitSpecialFixedTemplate
    };
}
