export type FloatProductLinePricingExcelTemplate = {
    CUSTOMERNUMBER: string | null,
    SHIPTO: string | null,
    NAME: string | null,
    PRODUCTLINE: string | null,
    DESCRIPTION: string | null,
    PRODUCTLINECATEGORY: string | null,
    CONTRACTNUMBER: string | null,
    CONTRACTNAME: string | null,
    PRICE: string | null,
    UOM: string | null,
    PERCENT: string | null,
    BEGINDATE: string | null,
    ENDDATE: string | null,
    EMPLOYEE: string | null,
    JOBTYPE: string | null,
    SUPPLIERRESPONSIBLE: string | null,
    COMMENTS: string | null,
    PROMOFLAG: string | null,
    "ADJY/N": string | null,
    "AUTOADJY/N": string | null,
    ADJTYPE: string | null,
    ADJAMT: string | null,
    "ADJ%": string | null,
}

export const FloatProductLinePricingExcelTemplate_Default = {
    CUSTOMERNUMBER: "",
    SHIPTO: "",
    NAME: "",
    PRODUCTLINE: "",
    DESCRIPTION: "",
    PRODUCTLINECATEGORY: "",
    CONTRACTNUMBER: "",
    CONTRACTNAME: "",
    PRICE: "",
    UOM: "",
    PERCENT: "",
    BEGINDATE: "",
    ENDDATE: "",
    EMPLOYEE: "",
    JOBTYPE: "",
    SUPPLIERRESPONSIBLE: "",
    COMMENTS: "",
    PROMOFLAG: "",
    "ADJY/N": "",
    "AUTOADJY/N": "",
    ADJTYPE: "",
    ADJAMT: "",
    "ADJ%": "",
} as FloatProductLinePricingExcelTemplate;
