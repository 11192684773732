import "./PricingPortalPage.scss"
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { ExcelTemplateTypes } from "enums";
import { templateConfig } from 'utils';
import TemplateOptions from 'components/TemplateOptions/TemplateOptions';
import OverviewSection from "components/OverviewSection/OverviewSection";
import ViewTemplateSection from "components/ViewTemplateSection/ViewTemplateSection";

// Testing a commit for workflow
// Context Imported
import { ExcelDataProvider } from 'components/Contexts/ExcelDataContext';

export default function PricingPortalPage() {  
    const [templateType, setTemplateType] = useState<ExcelTemplateTypes>(ExcelTemplateTypes.None),
          [isUploading, setIsUploading] = useState(false);

    const [changeNotes, setChangeNotes] = useState<string>('');

    return (
        <>
            <Helmet>
                <title>Pricing Portal | Template Drop</title>
            </Helmet>
            <div>
                <ExcelDataProvider>
                    <TemplateOptions templateProps={templateConfig}
                                     templateType={templateType}
                                     onTemplateTypeChange={(x) => setTemplateType(x)}
                                     setIsUploading={setIsUploading}
                                     changeNotes={changeNotes}
                                     setChangeNotes={setChangeNotes}
                    />
                    <OverviewSection/>
                    <ViewTemplateSection templateProps={templateConfig}
                                         templateType={templateType}
                                         isUploading={isUploading}
                                         text={changeNotes}
                                         setText={setChangeNotes}
                    />
                </ExcelDataProvider>
            </div>
        </>
    );
}
