import { GridCellProps } from "@progress/kendo-react-grid";
import "./TemplateHistoryStatusCell.scss";

interface CellProps {
    props: GridCellProps,
}

export default function TemplateHistoryStatusCell(cellProps: CellProps) {
    const { props } = cellProps,
          status = props.dataItem["Status"] ?? "",
          colorClass = (() => {
            switch(status) {
                case "Approved":
                    return "approved";
                case "In Process":
                    return "inprocess";
                case "Rejected":
                    return "rejected";
                default:
                    return "";
            }
          })();

    return (
        <td>
            <div className={`historyStatusCell ${colorClass}`}>
                <span>{status}</span>    
            </div>
        </td>
    )
}
