import TemplateButton from 'components/Buttons/TemplateButton';
import ErrorToggleButton from 'components/Buttons/ErrorToggleButton';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useRef } from 'react';
import { useInOutTransition } from 'hooks';
import './TemplateValidationErrors.scss';

interface Props {
    render: boolean,
    toggleRender: boolean,
    content: string[],
    message: string,
    dismissCallback: () => void,
    toggleErrors: () => void,
}

export default function TemplateValidationErrors({ render, toggleRender, content, message, dismissCallback, toggleErrors }: Props) {
    
    const { isActive, isNotTransitionedOut } = useInOutTransition(render),
          panelContent = useRef<string[] | null>(null);

    if (!isActive) {
        panelContent.current = content;
    }

    const localCopy: string[] = [...panelContent.current ?? []],
          title = localCopy.shift();

    return (isNotTransitionedOut && (
        <>
            <div className={`validationErrorsPanel ${render && isActive ? "" : "hidden"}`} data-testid="validationErrorsPanel">
            <p>{"Unable to validate the Excel template." +
                ((panelContent.current && panelContent.current.length > 0) ? "  See below for more details." : "")}
            </p>
            <div hidden={!panelContent.current || panelContent.current.length === 0}>
                <p>{title}</p>
                <ul>{localCopy.map((errorString, index) => <li key={`errorString${index}`}>{errorString}</li>)}</ul>
            </div>
            <p id="validationCorrectionMessage">{message}</p>
                <TemplateButton text="Close" btnIcon={faXmark} onClick={dismissCallback}></TemplateButton>
            </div>
            {toggleRender && (
                <ErrorToggleButton btnIcon={faTriangleExclamation} onClick={toggleErrors}></ErrorToggleButton>
            )}
        </>
    ));
}
