//Mapping display names to validation property names
export enum SpecialFixedPricingExcelColumnHeaders {
    customerNumber = "Customer Number",
    customerShipto = "Customer Shipto",
    name = "Customer Name",
    itemNumber = "Item Number",
    description = "Item Description",
    manufacturer = "Manufacturer Number",
    contractNumber = "Contract Number",
    contractName = "Contract Name",
    price = "Price",
    uom = "Unit of Measure",
    beginDate = "Begin Delivery Date",
    endDate = "End Delivery Date",
    employee = "Employee Number",
    jobType = "Job Type",
    supplierResponsible = "Supplier Responsible",
    comments = "Comments",
    promoFlag = "Promo Flag",
    nationalAccount = "National Account",
    floatFlag = "Float Flag",
    adjust = "Adj",
    autoAdjust = "Auto Adj",
    adjust1Type = "Adj #1 Type",
    adjust1Amount = "Adj #1 Amount",
    adjust1Percentage = "Adj #1 Percentage",
    adjust2Type = "Adj #2 Type",
    adjust2Amount = "Adj #2 Amount",
    adjust2Percentage = "Adj #2 Percentage",
    adjust3Type = "Adj #3 Type",
    adjust3Amount = "Adj #3 Amount",
    adjust3Percentage = "Adj #3 Percentage"
}

//Mapping raw excel property names to validation model property names
export enum SpecialFixedTemplateErrorKeys {
    customerNumber = "CUSTOMERNUMBER",
    customerShipto = "SHIPTO",
    name = "NAME",
    itemNumber = "ITEMNUMBER",
    description = "DESCRIPTION",
    manufacturer = "MANUFACTURER",
    contractNumber = "CONTRACTNUMBER",
    contractName = "CONTRACTNAME",
    price = "PRICE",
    uom = "UOM",
    beginDate = "BEGINDATE",
    endDate = "ENDDATE",
    employee = "EMPLOYEE",
    jobType = "JOBTYPE",
    supplierResponsible = "SUPPLIERRESPONSIBLE",
    comments = "COMMENTS",
    promoFlag = "PROMOFLAG",
    nationalAccount = "NATIONALACCOUNT",
    floatFlag = "FLOATFLAG",
    adjust = "ADJY/N",
    autoAdjust = "AUTOADJY/N",
    adjust1Type = "ADJ1TYPE",
    adjust1Amount = "ADJ1AMT",
    adjust1Percentage = "ADJ1%",
    adjust2Type = "ADJ2TYPE",
    adjust2Amount = "ADJ2AMT",
    adjust2Percentage = "ADJ2%",
    adjust3Type = "ADJ3TYPE",
    adjust3Amount = "ADJ3AMT",
    adjust3Percentage = "ADJ3%"
}

//Mapping raw excel property names to request/response property names
export enum SpecialFixedTemplateApiResponseKeys {
    CUSTOMERNUMBER = "CUSTOMERNUMBER",
    CUSTOMERSHIPTONUMBER = "SHIPTO",
    CUSTOMERNAME = "NAME",
    ITEMNUMBER = "ITEMNUMBER",
    ITEMDESCRIPTION = "DESCRIPTION",
    MANUFACTURERNUMBER = "MANUFACTURER",
    CONTRACTNUMBER = "CONTRACTNUMBER",
    CONTRACTDESCRIPTION = "CONTRACTNAME",
    PRICEAMOUNT = "PRICE",
    PRICEUNITOFMEASURE = "UOM",
    PRICEBEGINDATE = "BEGINDATE",
    PRICEENDDATE = "ENDDATE",
    EMPLOYEENUMBER = "EMPLOYEE",
    EMPLYEENUMBER = "EMPLYEE", //Misspelled in original template
    JOBTYPE = "JOBTYPE",
    SUPPLIERINDICATOR = "SUPPLIERRESPONSIBLE",
    COMMENT = "COMMENTS",
    PROMOFLAG = "PROMOFLAG",
    NATIONALACCOUNT = "NATIONALACCT", //Deprecated field, ignored
    FLOATFLAG = "FLOATFLAG", 
    ADJUSTMENTFLAG = "ADJY/N",
    AUTOFLAG = "AUTOADJY/N",
    ADJUSTMENT1TYPE = "ADJ1TYPE",
    ADJUSTMENT1AMOUNT = "ADJ1AMT",
    ADJUSTMENT1PERCENT = "ADJ1%",
    ADJUSTMENT2TYPE = "ADJ2TYPE",
    ADJUSTMENT2AMOUNT = "ADJ2AMT",
    ADJUSTMENT2PERCENT = "ADJ2%",
    ADJUSTMENT3TYPE = "ADJ3TYPE",
    ADJUSTMENT3AMOUNT = "ADJ3AMT",
    ADJUSTMENT3PERCENT = "ADJ3%"
}
