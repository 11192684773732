import { TemplateValidationRequest } from "types/TemplateValidationRequest";

export type SupplierPricingExcelApiValidationRequest = TemplateValidationRequest & {
    SupplierPricingTemplate: SupplierPricingExcelApiValidationRequestData[],
    AllowIncompleteProductLines: boolean
};

export type SupplierPricingExcelApiValidationRequestData = {
    PriceListNumber: string | null,
    VendorNumber: number | null,
    VendorName: string | null,
    PriceBeginDate: string | null,
    PriceEndDate: string | null,
    PriceDateType: string | null,
    RegionCovered: string | null,
    SKUorModel: string | null,
    GTIN: string | null,
    ProductDescription: string | null,
    PriceUnitOfMeasure: string | null,
    PriceBracket1ID: string | null,
    PriceBracket1Price: number | null,
    PriceBracket1QtyDescription: string | null,
    PriceBracket2ID: string | null,
    PriceBracket2Price: number| null,
    PriceBracket2QtyDescription: string | null,
    PriceBracket3ID: string | null,
    PriceBracket3Price: number | null,
    PriceBracket3QtyDescription: string | null,
    PriceBracket4ID: string | null,
    PriceBracket4Price: number | null,
    PriceBracket4QtyDescription: string | null,
    PriceBracket5ID: string | null,
    PriceBracket5Price: number | null,
    PriceBracket5QtyDescription: string | null,
    PriceBracket6ID: string | null,
    PriceBracket6Price: number | null,
    PriceBracket6QtyDescription: string | null, 
    PriceBracket7ID: string | null,
    PriceBracket7Price: number | null,
    PriceBracket7QtyDescription: string | null,
    PriceBracket8ID: string | null,
    PriceBracket8Price: number | null,
    PriceBracket8QtyDescription: string | null,
    PriceBracket9ID: string | null,
    PriceBracket9Price: number | null,
    PriceBracket9QtyDescription: string | null,
    PriceBracket10ID: string | null,
    PriceBracket10Price: number | null,
    PriceBracket10QtyDescription: string | null,
    ProductLine: string | number | null
};

export const SupplierPricingExcelApiValidationRequestData_Default: SupplierPricingExcelApiValidationRequestData = {
    PriceListNumber: "",
    VendorNumber: 0,
    VendorName: "",
    PriceBeginDate: "",
    PriceEndDate: "",
    PriceDateType: "",
    RegionCovered: "",
    SKUorModel: "",
    GTIN: "",
    ProductDescription: "",
    PriceUnitOfMeasure: "",
    PriceBracket1ID: "",
    PriceBracket1Price: 0.0,
    PriceBracket1QtyDescription: "",
    PriceBracket2ID: "",
    PriceBracket2Price: 0.0,
    PriceBracket2QtyDescription: "",
    PriceBracket3ID: "",
    PriceBracket3Price: 0.0,
    PriceBracket3QtyDescription: "",
    PriceBracket4ID: "",
    PriceBracket4Price: 0.0,
    PriceBracket4QtyDescription: "",
    PriceBracket5ID: "",
    PriceBracket5Price: 0.0,
    PriceBracket5QtyDescription: "",
    PriceBracket6ID: "",
    PriceBracket6Price: 0.0,
    PriceBracket6QtyDescription: "",
    PriceBracket7ID: "",
    PriceBracket7Price: 0.0,
    PriceBracket7QtyDescription: "",
    PriceBracket8ID: "",
    PriceBracket8Price: 0.0,
    PriceBracket8QtyDescription: "",
    PriceBracket9ID: "",
    PriceBracket9Price: 0.0,
    PriceBracket9QtyDescription: "",
    PriceBracket10ID: "",
    PriceBracket10Price: 0.0,
    PriceBracket10QtyDescription: "",
    ProductLine: ""
};
