export type FloatItemPricingExcelTemplate = {
    CUSTOMERNUMBER: string | null,
    SHIPTO: string | null,
    NAME: string | null,
    ITEMNUMBER: string | null,
    DESCRIPTION: string | null,
    MANUFACTURER: string | null,
    CONTRACTNUMBER: string | null,
    CONTRACTNAME: string | null,
    PRICE: string | null,
    UOM: string | null,
    BEGINDATE: string | null,
    ENDDATE: string | null,
    EMPLYEE: string | null, //Misspelled in original template
    EMPLOYEE: string | null,
    JOBTYPE: string | null,
    SUPPLIERRESPONSIBLE: string | null,
    COMMENTS: string | null,
    PROMOFLAG: string | null,
    NATIONALACCT: string | null, //Deprecated field, ignored
    FLOATTYPE: string | null, //Deprecated field, ignored
    "ADJY/N": string | null,
    "AUTOADJY/N": string | null,
    ADJTYPE: string | null,
    ADJAMT: string | null,
    "ADJ%": string | null,
}

export const FloatItemPricingExcelTemplate_Default = {
    CUSTOMERNUMBER: "",
    SHIPTO: "",
    NAME: "",
    ITEMNUMBER: "",
    DESCRIPTION: "",
    MANUFACTURER: "",
    CONTRACTNUMBER: "",
    CONTRACTNAME: "",
    PRICE: "",
    UOM: "",
    BEGINDATE: "",
    ENDDATE: "",
    EMPLYEE: "",
    EMPLOYEE: "",
    JOBTYPE: "",
    SUPPLIERRESPONSIBLE: "",
    COMMENTS: "",
    PROMOFLAG: "",
    NATIONALACCT: "",
    FLOATTYPE: "",
    "ADJY/N": "",
    "AUTOADJY/N": "",
    ADJTYPE: "",
    ADJAMT: "",
    "ADJ%": "",
} as FloatItemPricingExcelTemplate;
