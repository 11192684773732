import './RootLayout.scss';
import { Outlet, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Header,
    Footer,
    //Sidebar
} from 'components';
import { useOktaAuth } from '@okta/okta-react';

export default function RootLayout() {
    let pageName = "";
    let pageTitle = "";

    //Will need to be changed to be supplier specific
    const { authState } = useOktaAuth();
    const userName = authState?.isAuthenticated ? authState.idToken?.claims.name : "";
    const supplierName = authState?.isAuthenticated ? authState.idToken?.claims.email : "";
    const vendorNumber = authState?.isAuthenticated ? authState.idToken?.claims.auth_time : 0;

    const location = useLocation();
    switch(location.pathname) {
        case '/':
            pageName = "";
            pageTitle = "Pricing Manager";
            break;
        case '/history':
            pageName = "";
            pageTitle = "Pricing Manager";
            break;
        case "/dashboard":
            pageName = "Dashboard";
            pageTitle = "Pricing Portal | Dashboard";
            break;
        case "/supplierLetters":
            pageName = "Supplier Letters";
            pageTitle = "Pricing Portal | Supplier Letters";
            break;
        case "/invoices":
            pageName = "Invoices";
            pageTitle = "Pricing Portal | Invoices";
            break;
        case "/settings":
            pageName = "";
            pageTitle = "Pricing Manager | Settings";
            break;
        default: 
            pageName = "Dot Foods Inquiry/Dispute";
            pageTitle = "Inquiry/Dispute";
    }

    return(
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <div className="page">
                <div className="page-content">
                    <Header pageName={pageName} userName={userName} supplierName={supplierName} vendorNumber={vendorNumber?.toString()} />
                    <main>
                        <Outlet />  
                    </main>
                    <Footer />
                </div>
            </div>
        </>
    );
}


