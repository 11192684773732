import React, { createContext, useState, useContext } from 'react';

const ExcelDataContext = createContext();

export const useExcelData = () => useContext(ExcelDataContext);

export const ExcelDataProvider = ({ children }) => {
    const [excelData, setExcelData] = useState(null),
          [excelErrors, setExcelErrors] = useState(null);

    return (
        <ExcelDataContext.Provider value={{ excelContext: { excelData, setExcelData }, 
                                            errorContext: { excelErrors, setExcelErrors }}}>
            {children}
        </ExcelDataContext.Provider>
    );
};
