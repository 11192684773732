export default function BrandPromise() {
    return (
        <>
            <img className="brand-promise"
                src={`${process.env.PUBLIC_URL}/BrandPromise.svg`}
                alt="Dot Foods"
            >
            </img>
        </>
    );
}