import { SupplierPricingTemplateHistoryApiResponse, 
         SupplierPricingTemplateHistoryApiResponseDataRow, 
         SupplierPricingExcelTemplateHeaderRows_AoA } from "types";
import { makeDeepCopy, commonRegex, dateFormatter} from "utils";
import * as XLSX from "xlsx";

export function useCosmosExcelConverterService() {

    const convertSupplierPricingTemplate = (cosmosDocument: SupplierPricingTemplateHistoryApiResponse) => {
        const { FileName: fileName, Data: data, Created: creationDate } = cosmosDocument,
              outFileName = generateExcelFileName(fileName ?? "", creationDate ?? ""),
              headerRows = makeDeepCopy(SupplierPricingExcelTemplateHeaderRows_AoA),
              
              //construct supplier template AOA
              excelData = data.map(x => {
                  const inputRow = x as SupplierPricingTemplateHistoryApiResponseDataRow,
                        outputRow: string[] = [
                            inputRow.PriceListNumber ?? "",
                            inputRow.VendorNumber ?? "",
                            inputRow.VendorName ?? "",
                            inputRow.PriceBeginDate ?? "",
                            inputRow.PriceEndDate ?? "",
                            inputRow.PriceDateType ?? "",
                            inputRow.Region ?? "",
                            inputRow.Sku ?? "",
                            inputRow.Gtin ?? "",
                            inputRow.ProductDescription ?? "",
                            inputRow.Uom ?? ""
                        ];

                        const rowsBeforeBrackets = outputRow.length;

                        for (let i = 1; i <= 10; i++) {
                            const nextBracketStartIndex = rowsBeforeBrackets + ((3 * i) - 3);
                            outputRow[nextBracketStartIndex] = inputRow.PriceBrackets
                                                                       ?.find(x => x.TemplateNumber === i.toString())
                                                                       ?.Id ?? "";
                            outputRow[nextBracketStartIndex + 1] = inputRow.PriceBrackets
                                                                           ?.find(x => x.TemplateNumber === i.toString())
                                                                           ?.Price ?? "";
                            outputRow[nextBracketStartIndex + 2] = inputRow.PriceBrackets
                                                                           ?.find(x => x.TemplateNumber === i.toString())
                                                                           ?.QtyDescription ?? "";
                        }

                        outputRow.push(inputRow.ProductLine ?? "");
                        return outputRow;
              });
        writeExcelFileFromAoA(excelData, outFileName, headerRows);
    };

    const generateExcelFileName = (fileName: string, creationDate?: string) => {
        const date = creationDate ? new Date(Date.parse(creationDate)) 
                                  : new Date(),
              dateString = dateFormatter.YYYYMMDD(
                { year: date.getFullYear(), 
                  month: date.getMonth() + 1, 
                  day: date.getDate() }
              );
              
        if (!fileName) 
            return `${dateString}_SupplierListPricing.xlsx`;

        const parsedFileName = commonRegex.fileExtension.exec(fileName)?.groups;
        return parsedFileName ? `${dateString}_${parsedFileName["fileName"]}.xlsx` 
                              : `${dateString}_${fileName}.xlsx`;
    };

    const writeExcelFileFromAoA = (data: any[][], fileName: string, headers?: string[][]) => {
        const shouldSkipHeaders = headers?.length !== 0,
              workbook = XLSX.utils.book_new(),
              originRow = (headers?.length ?? 0) + 1,
              worksheet = XLSX.utils.aoa_to_sheet([[]]);

        if (headers && shouldSkipHeaders) {
            for (const [index, headerRow] of headers.entries()) {
                XLSX.utils.sheet_add_aoa(worksheet, [headerRow], { origin: `A${index + 1}`});
            }
        }
        XLSX.utils.sheet_add_aoa(worksheet, data, { origin: `A${originRow}` });

        // if (headers && headers?.length !== 0 && headers[0].length) {
        //     worksheet["!merges"] = (() => {
        //         const merges = [],
        //               headerRows = headers.length;
        //         for (let i = 0; i < headers[0].length; i++) {
        //             merges.push({ s: {c: i, r: 0}, e: {c: i, r: headerRows - 1 } });
        //         }
        //         return merges;
        //     })();
        // }

        XLSX.utils.book_append_sheet(workbook, worksheet, "Supplier List Pricing Template");
        XLSX.writeFileXLSX(workbook, fileName, { type: "buffer" });
    }

    return { convertSupplierPricingTemplate };
}
