import './HistoryRow.scss';
import { faTableList } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from 'react'

type Props = {
    info: string,
    index: number,
    status: string,
    date: string,
    priceListNumber: string,
}

enum HistoryRowStatus {
    Accepted = 'Accepted',
    UnderReview = 'Under Review',
    Rejected = 'Rejected'
};

export default function HistoryRow({info, index, status, date, priceListNumber}:Props) {

    useEffect(() => {
        statusColor();
      });

    function statusColor () {
        let statusBox = document.getElementsByClassName('statusBox') as HTMLCollectionOf<HTMLElement>;
        let statusText = document.getElementsByClassName('status') as HTMLCollectionOf<HTMLElement>;

        for (var i in statusText) {
            let boxElement = statusBox[i];
            let textElement = statusText[i];
            let status = statusText[i].innerText;

            toggleStatusClass(boxElement, status);
            toggleStatusClass(textElement, status);
        }
    };

    function toggleStatusClass (element : HTMLElement, status : string) {
        
        if (typeof element == 'undefined') return;
        switch(status) {
            case HistoryRowStatus.Accepted:
                element.classList.remove("rowUnderReview");
                element.classList.remove("rowRejected");
                element.classList.add("rowAccepted");
                break;
            case HistoryRowStatus.UnderReview:
                element.classList.remove("rowAccepted");
                element.classList.remove("rowRejected");
                element.classList.add("rowUnderReview");
                break;
            case HistoryRowStatus.Rejected:
                element.classList.remove("rowAccepted");
                element.classList.remove("rowUnderReview");
                element.classList.add("rowRejected");
                break;
        }
    };

    return (
        <>
            <div className='historyRow'>
                <h2 className='iconSize'><FontAwesomeIcon className="iconSymbol" icon={faTableList} size='xl'/></h2>
                <div className='historyRowLeft'>
                    <p className='date'>
                        {info} <br/>
                        {info} {info}
                    </p>

                    <div className='statusBox'>
                        <p className='status'>{status}</p>
                    </div>
                </div>
                <div className='historyRowRight'>
                    <p className='date'><strong>{date}</strong></p>
                    <div className='priceListNumberBox'>
                        <p>{priceListNumber}</p>
                    </div>
                </div>
            </div>
            {index !== 4 && 
                <hr className='rowDivider'/>
            }
        </>
    );
}