import { ColorTypes } from "enums";

export type ToggleElementProps = {
    element: HTMLElement,
    shouldBeVisible: boolean,
    colorType: ColorTypes | null,
    colorName: string | null,
    text: string | null,
};

export const toggleElement = (props: ToggleElementProps) => {
    const { element, colorType, colorName, text, shouldBeVisible } = props;
    if (colorName && colorType)
    {
        //This is to match our css color classes, e.g. --dot-brand-blue
        const regex : RegExp = /^--(dot-|custom-)[^-]+.*$/i;
        for (const className of props.element.classList ?? []) {
            if(regex.test(className)) {
                element.classList.remove(className);
            }
        }
        element.classList.add(`--${colorType}-${colorName}`);
    }
    if (text || text === "")
    {
        element.innerHTML = text!;
    }
    element.style.visibility = shouldBeVisible ? "visible" : "hidden";
};
