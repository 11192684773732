export enum ValidPriceDateTypes {
    Delivery,
    Order
}

export enum ValidUnitsOfMeasure {
    CA,
    LB,
    DZ,
    EA
}

export enum YesOrNo {
    Yes = "Y",
    No = "N"
}

export enum SpecialPricingAdjustmentCodes {
    BillbackSupplier = 1,
    CreditCustomer = 2,
    CreditSupplier = 3,
    BillbackSupplierPercentage = 4,
    CreditCustomerPercentage = 5,
    CreditSupplierPercentage = 6
}

export enum TemplateLevelErrorKeys {
    Generic = "Generic",
    ProductLineIncomplete = "ProductLineIncomplete"
}
