import "./LoadingAnimation.scss";
import { useCallback, useEffect, useState } from 'react';

interface Props {
    render: boolean,
}
export default function LoadingAnimation({ render }: Props) {
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(true);

    const toggleAfterDelay = useCallback(() => {
        setTimeout(() => {
            setShouldDisplay(render);
        }, 200);
    }, [render]);

    //Slightly delays the transition in/out css effect
    useEffect(() => {
        toggleAfterDelay();
    }, [render, toggleAfterDelay]);

    return (
        <div className={shouldDisplay ? "loader-overlay" : "hidden"}>
            <div className="loader"></div>
        </div>
    )
}