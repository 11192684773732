export default function DotPrivacyTerms(){
    return(
        <>
            <div>
                <a
                    href="https://www.dotfoods.com/privacy-policy/"
                    aria-label="Privacy Policy"> 
                    Privacy Policy
                </a> 
                &nbsp;,&nbsp;
                <a
                    href="https://www.dotfoods.com/terms-of-use/"
                    aria-label="Terms of Use"> 
                    Terms of Use
                </a>
            </div>
        </>
    );
}