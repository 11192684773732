import './ViewTemplateSection.scss';
import { useExcelData } from 'components/Contexts/ExcelDataContext';
import { useMemo } from 'react';
import TemplateButton from 'components/Buttons/TemplateButton';
import { ViewTemplateTable } from './ViewTemplateTable';
import ChangeNotes from './ChangeNotes'
import { ExcelTemplateTypes } from 'enums';
import { ExcelTemplateProps } from 'types';

//FontAwesome icons
import { faArrowRotateRight } from "@fortawesome/pro-light-svg-icons";
import { faMagnifyingGlass, faMessageExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    templateType: ExcelTemplateTypes,
    templateProps: ExcelTemplateProps,
    isUploading: boolean,
    text: string; 
    setText: (value: string) => void; 
}

export default function ViewTemplateSection({ templateType, templateProps, isUploading, text, setText }: Props) {
    const { excelContext } = useExcelData(),
          { excelData } = excelContext;

    const resubmitHandler = () => {
        const submitButton = document.querySelector('.submitBtn > button') as HTMLElement;
        if (submitButton) {
            console.log("resubmit button clicked");
            submitButton.click();
        }
    };

    const numHeaders = useMemo(() => {
        switch (templateType) {
            case ExcelTemplateTypes.Supplier:
                return templateProps.supplierPricingTemplate.numHeaderRows;
            case ExcelTemplateTypes.FloatItem:
                return templateProps.floatItemPricingTemplate.numHeaderRows;
            case ExcelTemplateTypes.FloatProductLine:
                return templateProps.floatProductLinePricingTemplate.numHeaderRows;
            case ExcelTemplateTypes.Fixed:
                return templateProps.fixedPricingTemplate.numHeaderRows;
            //case ExcelTemplateTypes.Extension:
            default:
                return 0;
        }
    }, [templateType, templateProps]);


    return (
        <>
            {isUploading ? (
                <div className="templateLoaderContainer">
                    <span className="templateLoader"></span>
                </div>
            ) : (
                <>
                    {excelData && excelData.length > 0 && (
                        <div className='templateSection'>
                            <div className='viewTemplateHeaderSection'>
                                <h2>{"View Your Template"}&nbsp;
                                    <FontAwesomeIcon id="uploadTemplateIcon" icon={faMagnifyingGlass} />
                                    </h2>
                                    <div className='changeNotesContainer'>
                                        <ChangeNotes text={text} setText={setText} />
                                </div>
                            </div>
                            <div className='resubmitSection'>
                                <TemplateButton text='RESUBMIT' btnIcon={faArrowRotateRight} onClick={resubmitHandler} />
                                <p className='templateBtnText'>Resubmit This Template</p>
                            </div>
                        </div>
                    )}
                    {excelData && excelData.length > 0 ? (
                        excelData.length <= 2999 ? (
                            <div>
                                <div className='spacer'>&nbsp;</div>
                                <div className='tableContainer'>
                                    <ViewTemplateTable templateType={templateType} numHeaders={numHeaders} />
                                </div>
                            </div>
                        ) : (
                            <div className='templateSection'>
                                <div className='viewTemplateHeaderSection'>
                                    <h3 className="warningMessage">
                                        Sorry, this template is too large to view, please try submitting to see errors and correct them in Excel before reuploading. &nbsp;
                                        <FontAwesomeIcon className="warningMessageIcon" icon={faMessageExclamation} />
                                    </h3>
                                </div>
                            </div>
                        )
                        ) : null }
                </>
            )}
        </>
    );
}
