export default function DotCopyright(){

    const currentDate: Date = new Date();
    const currentYear: string = currentDate.getFullYear().toString();

    return(
        <>
            <div>
                &copy; 1995-{currentYear} Dot Foods, Inc. All Rights Reserved.
                <sup className="footer-right-trademark-symbol">&reg;</sup> 
            </div>
        </>
    );
}