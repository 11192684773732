import { useCallback, useEffect, useState } from "react";

export function useInOutTransition(shouldRender: boolean, activationDelay: number = 125, transitionDuration: number = 500) {
    const [isActive, setIsActive] = useState<boolean>(false),
          [isNotTransitionedOut, setIsNotTransitionedOut] = useState<boolean>(false);

    const toggleAfterDelay = useCallback(() => {
        setTimeout(() => {
            setIsActive(shouldRender);
        }, activationDelay);
    }, [shouldRender, activationDelay]);

    //Slightly delays the transition in/out css effect
    useEffect(() => {
        toggleAfterDelay();
    }, [shouldRender, toggleAfterDelay]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        timer = setTimeout(() => {
            setIsNotTransitionedOut(true);
        }, transitionDuration);
        return () => clearTimeout(timer);
    }, [transitionDuration]);

    return { isActive, isNotTransitionedOut };
}
