
import { useMemo, useState } from "react";
import { Grid, 
         GridColumn, 
         GridDataStateChangeEvent, 
         GridFilterOperators } from "@progress/kendo-react-grid";
import { process, 
         State, 
         DataResult, 
         FilterDescriptor, 
         CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { ExcelTemplateTypes, TemplateStatus } from "enums";
import { useInOutTransition } from "hooks";
import { TemplateHistoryApiResponseValue, 
         HistoryDisplayTableData, 
         DateRange,
         SupplierPricingTemplateHistoryApiResponse, 
         TextExpandPanelProps} from "types";
import { getEnumKeys } from "utils";
import { TemplateHistoryActionCell, 
         TemplateHistoryStatusFilter,
         TemplateHistoryDateRangeFilter, 
         TemplateHistoryExpandableTextCell,
         TemplateHistoryStatusCell} from "components";
import "./TemplateHistorySearchResultsTable.scss";
import TemplateHistoryTextExpandPanel from "./Panels/TemplateHistoryTextExpandPanel";

interface HistoryTableProps {
    shouldRender: boolean,
    className?: string,
    templateType: ExcelTemplateTypes,
    tableData: TemplateHistoryApiResponseValue[],
    onDownload: (id: string) => Promise<void>
}

const helpers = {
    cellListFormatter: (listContent: string) => {
        const formatted =  listContent.split(",")
                                      .map((substr) => helpers.fixAllCaps(substr))
                                      .join("\n");
        return formatted;
    },

    fixAllCaps: (str: string) => {
        if (!str) return "";
        const nonWordChar = /[^'\w]/i,
              output = str.toLocaleLowerCase()
                          .trim()
                          .split("")
                          .map((char, index, array) => {
                              return index === 0 || nonWordChar.test(array[index-1]) ? char.toLocaleUpperCase()
                                                                                     : char;
                          })
                          .join("");
        return output;
    },

    populateSupplierPricingTemplateColumns: (data: TemplateHistoryApiResponseValue[]) => data.map(x => {
        const details = (x as SupplierPricingTemplateHistoryApiResponse),
              nbsp = "\u00a0", //non-breaking space
              vendorString = details ? (details.Vendors?.map(x => `${x.VendorName}${nbsp}(${x.VendorNumber})`)
                                                        .join(", ") ?? "") 
                                     : "",
              productLineString = details ? (details.ProductLines?.map(x => `${x.ProductLineName}${nbsp}(${x.ProductLineNumber})`)
                                                                  .join(", ") ?? "") 
                                          : "";
        return { ...x, 
                 IsComplete: !(x as any)["MissingItems"], 
                 Vendors: vendorString, 
                 ProductLines: productLineString };
    }) as HistoryDisplayTableData[],
    
    preprocess: (data: TemplateHistoryApiResponseValue[]) => {
        const processed = data.map(x => {
            const output = x as HistoryDisplayTableData;
            output.CreatedDate = new Date(Date.parse(x.Created ?? ""));
            output.Status = getEnumKeys(TemplateStatus).find(s => TemplateStatus[s] === x.Status) ?? "N/A"
            return output;
        });
        return processed as HistoryDisplayTableData[];
    }
};

const defaults = {
    initialDataState: { take: 10, 
                        skip: 0 } as State,
    dateFilter: { logic: "and",
                  filters: [{ field: "CreatedDate",
                              operator: "gte",
                              value: new Date() }]
    } as CompositeFilterDescriptor,
    textExpandProps: {
        shouldRender: false,
        content: "",
        dataItemDetails: {
            fieldDisplayName: "",
            formatter: undefined
        }
    } as TextExpandPanelProps,

    //Custom filter list to remove the "is null" and "is not null" filters, since 
    //most users have no idea what that means.  The fact that the Kendo team thought they 
    //should be opt-out rather than opt-in is pretty wild.
    filters: [
        { text: 'grid.filterContainsOperator', operator: 'contains' },
        { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
        { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
    ]
};

export default function TemplateHistorySearchResultsTable(props: HistoryTableProps) {
    const { shouldRender, tableData, templateType, className, onDownload } = props,
          [dataState, setDataState] = useState<State>(defaults.initialDataState),
          [expandedTextProps, setExpandedTextProps] = useState<TextExpandPanelProps>(defaults.textExpandProps),
          [statusFilterValues, setStatusFilterValues] = useState<string[]>([]),
          [createdDateRange, setCreatedDateRange] = useState<DateRange>({minDate: null, maxDate: null});

    const { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 25, 500);

    const preprocessedTableData = 
        templateType === ExcelTemplateTypes.Supplier ? helpers.preprocess(helpers.populateSupplierPricingTemplateColumns(tableData))
                                                     : helpers.preprocess(tableData);

    const gridFilterOperators = useMemo(() => {
        return {
            text: defaults.filters,
            numeric: defaults.filters,
            date: defaults.filters,
            boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }]
        } as GridFilterOperators
    }, []);

    const onTextCellExpansion = (title: string, value: string, formatFunction?: (x: string) => string) => {
        setExpandedTextProps({...expandedTextProps, 
                              shouldRender: true,
                              dataItemDetails: { ...expandedTextProps.dataItemDetails, 
                                                 formatter: formatFunction,
                                                 fieldDisplayName: title },
                              content: value});
    };

    const onTextCellExpansionClose = () => {
        setExpandedTextProps({...expandedTextProps, 
                              shouldRender: false });
    }

    const onStatusFilterClear = () => {
        const newDataState = { ...dataState },
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor,
                        composite = x as CompositeFilterDescriptor;
                  return !(filterDescriptor.field === "Status" || 
                           composite.filters?.find(f => (f as FilterDescriptor).field === "Status"));
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters];
        }
        setDataState(newDataState);
        setStatusFilterValues([]);
    };

    const onStatusFilterUpdate = (showStatus: string[]) => {
        const newDataState = { ...dataState },
              newFilters = {
                  logic: "or",
                  filters: showStatus.map(x => {
                      return { field: "Status", 
                              operator: "eq", 
                              value: x } as FilterDescriptor
                  })
              } as CompositeFilterDescriptor,
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor,
                        composite = x as CompositeFilterDescriptor;
                  return !(filterDescriptor.field === "Status" || 
                           composite.filters?.find(f => (f as FilterDescriptor).field === "Status"));
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters, newFilters];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters, newFilters];
        }
        setDataState(newDataState);
        setStatusFilterValues(showStatus);
    };

    const onCreatedDateFilterClear = () => {
        const newDataState = { ...dataState },
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor;
                  return !(filterDescriptor.field === "CreatedDate");
              }) ?? [];
        newDataState.filter!.filters = [...otherFilters];
        setDataState(newDataState);
        setCreatedDateRange({ minDate: null, maxDate: null });
    };

    const onCreatedDateFilterUpdate = (date: Date | null, operator: "lt" | "gt") => {
        const dateRange = { ...createdDateRange };

        if (operator === "gt")
            dateRange.minDate = date;
        else
            dateRange.maxDate = date;

        const newDataState = { ...dataState },
              newFilter = { field: "CreatedDate", 
                            operator: operator, 
                            value: date } as FilterDescriptor,
              otherFilters = dataState.filter?.filters.filter(x => {
                  const filterDescriptor = x as FilterDescriptor;
                  return !(filterDescriptor.field === "CreatedDate" && filterDescriptor.operator === operator);
              }) ?? [];
              
        if (newDataState.filter) 
            newDataState.filter.filters = [...otherFilters, newFilter];
        else {
            newDataState.filter = defaults.dateFilter;
            newDataState.filter.filters = [...otherFilters, newFilter];
        }
        setCreatedDateRange(dateRange);
        setDataState(newDataState);
    };

    return (isNotTransitionedOut && 
        <div className={`${className ?? ""} historySearchResults ${shouldRender && isActive ? "" : "hidden"}`}>
            {!(tableData?.length > 0) ? (
                <span id="noResultText">
                    <p>No results found.</p>
                </span>
            ) : (
                <Grid data={process(preprocessedTableData, dataState) as DataResult}
                      scrollable="none"
                      pageable={true}
                      sortable={true}
                      reorderable={true}
                      resizable={true} //this is buggy
                      filterable={true}
                      filterOperators={gridFilterOperators}
                      skip={dataState.skip}
                      take={dataState.take}
                      total={preprocessedTableData.length}
                      sort={dataState.sort}
                      filter={dataState.filter}
                      {...dataState}
                      onDataStateChange={(e: GridDataStateChangeEvent) => setDataState(e.dataState)}
                >
    
                    <GridColumn field="Vendors" 
                                minResizableWidth={150}
                                title="Vendor(s)"
                                resizable={true}
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="Vendors"
                                            fieldDisplayName="Vendor(s)"
                                            stringFormatter={helpers.fixAllCaps}
                                            autoEllipsis={true}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content, helpers.cellListFormatter)
                                            } 
                                /> }
                    />
                                
                    <GridColumn field="FileName" 
                                resizable={true}
                                minResizableWidth={150}
                                title="File Name" 
                    />
    
                    <GridColumn field="Status" 
                                filter="text"
                                width={175}
                                resizable={true}
                                minResizableWidth={175}
                                filterCell={_ => <TemplateHistoryStatusFilter statusValues={statusFilterValues}
                                                                              onFilterChange={onStatusFilterUpdate} 
                                                                              onFilterClear={onStatusFilterClear} />}
                                cell={props => <TemplateHistoryStatusCell props={props} />}
                                title="Status" 
                    />
    
                    <GridColumn field="SubmitterEmail" 
                                resizable={true}
                                minResizableWidth={100}
                                title="Submitted By" 
                    />
    
                    <GridColumn field="CreatedDate" 
                                title="Submission Date/Time" 
                                filter="date" 
                                resizable={true}
                                width={275}
                                minResizableWidth={275}
                                filterCell={_ => <TemplateHistoryDateRangeFilter minDate={createdDateRange.minDate} 
                                                                                 maxDate={createdDateRange.maxDate} 
                                                                                 onFilterChange={onCreatedDateFilterUpdate} 
                                                                                 onFilterClear={onCreatedDateFilterClear} />}
                                format={"{0:F}"}
                    />
    
                    <GridColumn field="ProductLines" 
                                minResizableWidth={150}
                                resizable={true}
                                title="Product Line(s)"
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="ProductLines"
                                            fieldDisplayName="Product Line(s)"
                                            stringFormatter={helpers.fixAllCaps}
                                            autoEllipsis={true}
                                            onClick={
                                                (title: string, content: string) => 
                                                    onTextCellExpansion(title, content, helpers.cellListFormatter)
                                            } 
                                /> }
                    />
                                
                    {(templateType === ExcelTemplateTypes.Supplier && 
                        <GridColumn field="IsComplete" 
                                    width={150}
                                    resizable={true}
                                    minResizableWidth={150}
                                    title="Complete Product Line(s)" 
                                    filter="boolean"
                        />
                    )}
                    
                    <GridColumn field="ChangeNotes" 
                                title="Change Notes" 
                                resizable={true}
                                minResizableWidth={150}
                                cell={cellProps => 
                                        <TemplateHistoryExpandableTextCell 
                                            props={cellProps} 
                                            fieldName="ChangeNotes"
                                            fieldDisplayName="Change Notes"
                                            autoEllipsis={true}
                                            onClick={onTextCellExpansion} 
                                /> }
                    />
    
                    <GridColumn field="Id"
                                filterable={false}
                                resizable={true}
                                minResizableWidth={100}
                                title="Save Excel File"
                                cell={cellProps => <TemplateHistoryActionCell props={cellProps} 
                                                                              onClick={onDownload} /> } 
                    />
    
                </Grid>
            )}
            <TemplateHistoryTextExpandPanel shouldRender={expandedTextProps.shouldRender}
                                            dataItemDetails={expandedTextProps.dataItemDetails}
                                            content={expandedTextProps.content} 
                                            dismissCallback={onTextCellExpansionClose}
            />

        </div>
    )
}
