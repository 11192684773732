import { makeDeepCopy } from 'utils';
import * as XLSX from 'xlsx';

export function useExcelHeaderPreprocessingService() {

    const preprocessExcelTemplateHeaders = (worksheet: XLSX.WorkSheet): XLSX.WorkSheet => {
        const regex = /^[A-Z]+1$/i, //Match header cells, like A1, BR1, CAB1, etc
              output = makeDeepCopy(worksheet),
              headers = Object.keys(worksheet)
                              .filter(x => regex.test(x));
        for (const header of headers) {
            if (worksheet[header]) {
                const newValue = worksheet[header].v.trim().toUpperCase();
                output[header].v = newValue;
                output[header].w = newValue;
                output[header].h = newValue;
                output[header].r = newValue;
            }
        }
        return output;
    }

    return { preprocessExcelTemplateHeaders };
}
