import "./TemplateButton.scss";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@progress/kendo-react-buttons";

type Props = {
    text: string,
    btnIcon: IconDefinition,
    disabled?: boolean,
    onClick?: () => void,
    className?: string
}

export default function TemplateButton({text, btnIcon, onClick, disabled = false, className}: Props) {
    return (
        <Button disabled={disabled} className={`templateButton${className ? ` ${className}` : ""}`} onClick={onClick}>
            {text !== "" && (<span className="buttonText">{text}</span>)}
            {<FontAwesomeIcon
                className="iconSymbol"
                icon={btnIcon}
                size={'lg'}
            />}
        </Button>
    )
}
