import './OverviewCard.scss';
import { IconDefinition } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import { ColorSettings } from 'types';

type Props = {
    colorSettings: ColorSettings,
    cardIcon: IconDefinition,
    cardCategory: string,
    notificationCount: number,
}

export default function OverviewCard({colorSettings, cardIcon, cardCategory, notificationCount}:Props) {
    return(
        <>
            <div className={`overviewCard --${colorSettings.colorType}-border-${colorSettings.key}`}>
                    <h5 className={`cardTitle --${colorSettings.colorType}-${colorSettings.key}`}>{<FontAwesomeIcon
                        className={`cardIcon --${colorSettings.colorType}-${colorSettings.key}`}
                        icon={cardIcon}
                        size={'sm'}
                    />} &nbsp;{cardCategory}</h5>
                <h1 className={`cardNumber --${colorSettings.colorType}-${colorSettings.key}`}>{notificationCount}</h1>
                <NavLink to="" style={{ textDecoration: 'None', outline: 'None' }}><h5 className='viewTemplates'>VIEW TEMPLATES</h5></NavLink>
            </div>   
        </>
    );
}
