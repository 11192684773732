import { monthDayMapping } from 'utils';
import { DateMetadata, ExcelViewerErrorData } from 'types';


export function useDateCheckerService() {

    const checkValidYear = (dateMetadata: DateMetadata, errors: ExcelViewerErrorData[]) => {
        const { fullDateString, date, dateType, dateColumnName, rowNumber } = dateMetadata,
              column = dateColumnName ? dateColumnName : dateType,
              formattedDate = `${date.year}` + 
                              `${date.month < 10 ? `0${date.month}`: date.month}` + 
                              `${date.day < 10 ? `0${date.day}`: date.day}`;
        const currentDate = new Date(),
              currentYear = currentDate.getFullYear(),
              //Month is zero indexed
              currentMonth = currentDate.getMonth() + 1,
              currentDay = currentDate.getDate(),
              currentDateNumeric = parseInt(`${currentYear}` + 
                                            `${currentMonth < 10 ? `0${currentMonth}` : currentMonth}` +
                                            `${currentDay < 10 ? `0${currentDay}` : currentDay}`);
        let errText: string = "";

        if(!date.year) {
            errText = `Unable to identify year from ${column} "${fullDateString}".`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        } 
        else if(currentDateNumeric > parseInt(formattedDate)) {
            errText = `Invalid year for ${column} "${fullDateString}", ` + 
                      `reason: must be current or future date.`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        }
        return true;
    }


    const checkValidMonth = (dateMetadata: DateMetadata, errors: ExcelViewerErrorData[]) => {
        const { fullDateString, date, dateType, dateColumnName, rowNumber } = dateMetadata,
              column = dateColumnName ? dateColumnName : dateType;
        let errText: string = "";
        if(!date.month){
            errText = `Unable to identify month from ${column} "${fullDateString}".`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        } 
        else if(date.month > 12 || date.month < 1) {
            errText = `Invalid month for ${column} "${fullDateString}", ` +
                      `reason: month must be in range 1-12.`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]))
            return false;
        }
        return true;
    }


    const checkValidDay = (dateMetadata: DateMetadata, 
                           errors: ExcelViewerErrorData[], 
                           invalidMonth: boolean = false, 
                           invalidYear: boolean = false) => {
        const { fullDateString, date, dateType, dateColumnName, rowNumber } = dateMetadata,
              column = dateColumnName ? dateColumnName : dateType;
        let errText: string = "";

        if(!date.day) {
            errText = `Unable to identify day from ${column} "${fullDateString}".`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        }
        else if(date.day > 31 || date.day < 1) {
            errText = `Invalid day for ${column} "${fullDateString}", ` + 
                      `reason: day must be in range 1-31.`;
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        } 
        else if(!invalidMonth && !invalidYear && !checkAllowedDayRange(date.day, date.month, date.year)) {
            const isLeapYear = checkIsLeapYear(date.year),
                  index = date.month-1,
                  isFeb29 = date.month === 2 && date.day === 29,
                  adjustForFeb = (!isLeapYear && date.month === 2),
                  febMax = isLeapYear ? monthDayMapping[1]!["maxDays"] : monthDayMapping[1]!["maxDays"] - 1,
                  monthKvp = monthDayMapping[index];

            errText = `Invalid day for ${column} "${fullDateString}", reason: ` +
                      `day outside of allowed range 1-${adjustForFeb ? febMax : monthKvp!["maxDays"]} ` +
                      `for ${monthKvp!["name"]}${isFeb29 ? " (not a leap year)" : ""}.`
            errors.push(new ExcelViewerErrorData(rowNumber ?? 0, dateType, [errText]));
            return false;
        }
        return true;
    }

    
    const checkIsLeapYear = (year: number) => {
        return (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0));
    }


    //Helper function, checks if a day falls within a given month.  
    //If the provided date is Feb 29, also checks for a leap year.
    const checkAllowedDayRange = (day: number, month: number, year: number) => {
        const kvp = monthDayMapping[month-1];
        if(day > kvp["maxDays"]) return false;
        if(month === 2 && day === 29) {
            return checkIsLeapYear(year);
        }
        return true;
    }
    
    return {
        checkValidYear,
        checkValidMonth, 
        checkValidDay,
        checkIsLeapYear,
        checkAllowedDayRange
    }
}
