interface SearchRadioInputProps {
    id: string,
    labelText: string,
    checkedCondition: boolean,
    className?: string,
    onClick: () => void
}

export default function SearchRadioInput(props: SearchRadioInputProps) {
    const { id, labelText, checkedCondition, className, onClick } = props;
    return (
        <span className={`${id}${className ? ` ${className}` : ""}`}>
            <input type="radio" 
                   name="searchType" 
                   id={id}
                   checked={checkedCondition}
                   onChange={onClick} />
            <label htmlFor={id} 
                   className={id}>{labelText}</label>
        </span>
    );
}