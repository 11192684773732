//Mapping display names to validation property names
export enum FloatProductLinePricingExcelColumnHeaders {
    customerNumber = "Customer Number",
    customerShipto = "Customer Shipto",
    name = "Customer Name",
    productLine = "Product Line",
    description = "Product Line Description",
    productLineCategory = "Product Line Category",
    contractNumber = "Contract Number",
    contractName = "Contract Name",
    price = "Price",
    uom = "Unit of Measure",
    percent = "Percentage",
    beginDate = "Begin Delivery Date",
    endDate = "End Delivery Date",
    employee = "Employee Number",
    jobType = "Job Type",
    supplierResponsible = "Supplier Responsible",
    comments = "Comments",
    promoFlag = "Promo Flag",
    adjust = "Adj",
    autoAdjust = "Auto Adj",
    adjustType = "Adj Type",
    adjustAmount = "Adj Amount",
    adjustPercentage = "Adj Percentage"
}

//Mapping raw excel property names to validation model property names
export enum FloatProductLineTemplateErrorKeys {
    customerNumber = "CUSTOMERNUMBER",
    customerShipto = "SHIPTO",
    name = "NAME",
    productLine = "PRODUCTLINE",
    description = "DESCRIPTION",
    productLineCategory = "PRODUCTLINECATEGORY",
    contractNumber = "CONTRACTNUMBER",
    contractName = "CONTRACTNAME",
    price = "PRICE",
    uom = "UOM",
    percent = "PERCENT",
    beginDate = "BEGINDATE",
    endDate = "ENDDATE",
    employee = "EMPLOYEE",
    jobType = "JOBTYPE",
    supplierResponsible = "SUPPLIERRESPONSIBLE",
    comments = "COMMENTS",
    promoFlag = "PROMOFLAG",
    adjust = "ADJY/N",
    autoAdjust = "AUTOADJY/N",
    adjustType = "ADJTYPE",
    adjustAmount = "ADJAMT",
    adjustPercentage = "ADJ%"
}

//Mapping raw excel property names to request/response property names
export enum FloatProductLineTemplateApiResponseKeys {
    CUSTOMERNUMBER = "CUSTOMERNUMBER",
    CUSTOMERSHIPTONUMBER = "SHIPTO",
    CUSTOMERNAME = "NAME",
    PRODUCTLINE = "PRODUCTLINE",
    PRODUCTDESCRIPTION = "DESCRIPTION",
    PRODUCTLINECATEGORY = "PRODUCTLINECATEGORY",
    CONTRACTNUMBER = "CONTRACTNUMBER",
    CONTRACTDESCRIPTION = "CONTRACTNAME",
    PRICEAMOUNT = "PRICE",
    PRICEUNITOFMEASURE = "UOM",
    PRICEPERCENTAGE = "PERCENT",
    PRICEBEGINDATE = "BEGINDATE",
    PRICEENDDATE = "ENDDATE",
    EMPLOYEENUMBER = "EMPLOYEE",
    JOBTYPE = "JOBTYPE",
    SUPPLIERINDICATOR = "SUPPLIERRESPONSIBLE",
    COMMENT = "COMMENTS",
    PROMOFLAG = "PROMOFLAG",
    ADJUSTMENTFLAG = "ADJY/N",
    AUTOFLAG = "AUTOADJY/N",
    ADJUSTMENTTYPE = "ADJTYPE",
    ADJUSTMENTAMOUNT = "ADJAMT",
    ADJUSTMENTPERCENT = "ADJ%"
}
