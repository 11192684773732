//Marker interface
export interface PricingExcelDataRow { }

export type ClientValidationResult = {
    requestData: PricingExcelDataRow[], 
    errorList: string[]
    errorCount: number
};

export interface ExcelPropertyInfo {
    dataRow: number,
    value: string | null,
    propName: string | null,
    log?: ExcelViewerErrorData[],
    errorKey?: string | null
};

export class ExcelViewerErrorData {
    row: number;
    column: string;
    info: string[];
    
    constructor();
    constructor(rowNumber: number);
    constructor(rowNumber: number, column?: string);
    constructor(rowNumber: number, column: string, info?: string[]);
    constructor(rowNumber?: number, column?: string, info?: string[]) {
        this.row = rowNumber ?? -1;
        this.column = column ?? "";
        this.info = info ?? [];
    }
}
