//Mapping display names to raw excel property names
export enum SupplierPricingExcelColumnHeaders {
    PRICE = "Price List Number",
    "VENDOR#" = "Vendor #",
    VENDORNAME = "Vendor Name",
    PRICEBEGIN = "Price Begin Effective Date",
    PRICEEND = "Price End Effective Date",
    PRICEDATETYPE = "Price Date Type",
    REGION = "Region Covered (Zone/Area)",
    "SKU/" = "SKU / Model #",
    MASTERPACK = "Master Pack 14 Digit GTIN (UPC)",
    PRODUCTDESCRIPTION = "Product Description",
    PRICEUOM = "Price UOM",
    PRICE_1 = "Price Brkt 1 ID",
    PRICE_2 = "Price Brkt 1 Price",
    PRICEBRKT1 = "Price Brkt 1 Qty Description",
    PRICE_3 = "Price Brkt 2 ID",
    PRICE_4 = "Price Brkt 2 Price",
    PRICEBRKT2 = "Price Brkt 2 Qty Description",
    PRICE_5 = "Price Brkt 3 ID",
    PRICE_6 = "Price Brkt 3 Price",
    PRICEBRKT3 = "Price Brkt 3 Qty Description",
    PRICE_7 = "Price Brkt 4 ID",
    PRICE_8 = "Price Brkt 4 Price",
    PRICEBRKT4 = "Price Brkt 4 Qty Description",
    PRICE_9 = "Price Brkt 5 ID",
    PRICE_10 = "Price Brkt 5 Price",
    PRICEBRKT5 = "Price Brkt 5 Qty Description",
    PRICE_11 = "Price Brkt 6 ID",
    PRICE_12 = "Price Brkt 6 Price",
    PRICEBRKT6 = "Price Brkt 6 Qty Description",
    PRICE_13 = "Price Brkt 7 ID",
    PRICE_14 = "Price Brkt 7 Price",
    PRICEBRKT7 = "Price Brkt 7 Qty Description",
    PRICE_15 = "Price Brkt 8 ID",
    PRICE_16 = "Price Brkt 8 Price",
    PRICEBRKT8 = "Price Brkt 8 Qty Description",
    PRICE_17 = "Price Brkt 9 ID",
    PRICE_18 = "Price Brkt 9 Price",
    PRICEBRKT9 = "Price Brkt 9 Qty Description",
    PRICE_19 = "Price Brkt 10 ID",
    PRICE_20 = "Price Brkt 10 Price",
    PRICEBRKT10 = "Price Brkt 10 Qty Description",
    PRODUCT = "Product Line"
}

//Mapping raw excel property names to request/response property names
export enum SupplierTemplateApiResponseKeys { 
    PRICELISTNUMBER = "PRICE",
    VENDORNUMBER = "VENDOR#",
    VENDORNAME = "VENDORNAME",
    PRICEBEGINDATE = "PRICEBEGIN",
    PRICEENDDATE = "PRICEEND",
    PRICEDATETYPE = "PRICEDATETYPE",
    REGIONCOVERED = "REGION",
    SKUORMODEL = "SKU/",
    GTIN = "MASTERPACK",
    PRODUCTDESCRIPTION = "PRODUCTDESCRIPTION",
    PRICEUNITOFMEASURE = "PRICEUOM",
    PRICEBRACKET1ID = "PRICE_1",
    PRICEBRACKET1PRICE = "PRICE_2",
    PRICEBRACKET1QTYDESCRIPTION = "PRICEBRKT1",
    PRICEBRACKET2ID = "PRICE_3",
    PRICEBRACKET2PRICE = "PRICE_4",
    PRICEBRACKET2QTYDESCRIPTION = "PRICEBRKT2",
    PRICEBRACKET3ID = "PRICE_5",
    PRICEBRACKET3PRICE = "PRICE_6",
    PRICEBRACKET3QTYDESCRIPTION = "PRICEBRKT3",
    PRICEBRACKET4ID = "PRICE_7",
    PRICEBRACKET4PRICE = "PRICE_8",
    PRICEBRACKET4QTYDESCRIPTION = "PRICEBRKT4",
    PRICEBRACKET5ID = "PRICE_9",
    PRICEBRACKET5PRICE = "PRICE_10",
    PRICEBRACKET5QTYDESCRIPTION = "PRICEBRKT5",
    PRICEBRACKET6ID = "PRICE_11",
    PRICEBRACKET6PRICE = "PRICE_12",
    PRICEBRACKET6QTYDESCRIPTION = "PRICEBRKT6", 
    PRICEBRACKET7ID = "PRICE_13",
    PRICEBRACKET7PRICE = "PRICE_14",
    PRICEBRACKET7QTYDESCRIPTION = "PRICEBRKT7",
    PRICEBRACKET8ID = "PRICE_15",
    PRICEBRACKET8PRICE = "PRICE_16",
    PRICEBRACKET8QTYDESCRIPTION = "PRICEBRKT8",
    PRICEBRACKET9ID = "PRICE_17",
    PRICEBRACKET9PRICE = "PRICE_18",
    PRICEBRACKET9QTYDESCRIPTION = "PRICEBRKT9",
    PRICEBRACKET10ID = "PRICE_19",
    PRICEBRACKET10PRICE = "PRICE_20",
    PRICEBRACKET10QTYDESCRIPTION = "PRICEBRKT10",
    PRODUCTLINE = "PRODUCT"
}
