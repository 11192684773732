export const monthDayMapping = [
    {name: "January", maxDays: 31},
    {name: "February", maxDays: 29},
    {name: "March", maxDays: 31},
    {name: "April", maxDays: 30},
    {name: "May", maxDays: 31},
    {name: "June", maxDays: 30},
    {name: "July", maxDays: 31},
    {name: "August", maxDays: 31},
    {name: "September", maxDays: 30},
    {name: "October", maxDays: 31},
    {name: "November", maxDays: 30},
    {name: "December", maxDays: 31}
];
