import { useOktaAuth } from "@okta/okta-react"
import { useEffect } from "react"
import { POST_LOGOUT_REDIRECT_URI } from "utils"


export default function LogoutPage() {
    const { oktaAuth } = useOktaAuth()

    useEffect(() => {
        const signOut = async () => {
            await oktaAuth.signOut({postLogoutRedirectUri: `${POST_LOGOUT_REDIRECT_URI}` })
        }

        signOut()
    },[oktaAuth])

    return(<div>Logging out...</div>)
}