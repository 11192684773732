import { GridCellProps } from "@progress/kendo-react-grid";
import { faSave } from "@fortawesome/pro-solid-svg-icons";
import "./TemplateHistoryActionCell.scss";
import TemplateButton from "components/Buttons/TemplateButton";

interface ActionCellProps {
    props: GridCellProps,
    onClick: (id: string) => void
}

export default function TemplateHistoryActionCell(cellProps: ActionCellProps) {
    const { onClick: callback, props } = cellProps,
          downloadClickHandler = async () => {
              await callback(props.dataItem["Id"] ?? "");
          };
    return (
        <td>
            <div className={"historyActionsCell"}>
                <TemplateButton className="downloadIconButton" 
                                onClick={downloadClickHandler} 
                                btnIcon={faSave}
                                text="" />
            </div>
        </td>
    )
}
