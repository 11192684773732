import { ExcelTemplateTypes, SupplierTemplateSearchTypes, TemplateStatus } from "enums";
import { useInOutTransition } from "hooks";
import { useEffect, useRef, useState } from "react";
import { faMagnifyingGlassPlay } from "@fortawesome/pro-light-svg-icons";
import TemplateButton from "components/Buttons/TemplateButton";
import SupplierPricingTemplateSearchOptions from "./SearchOptions/SupplierPricingTemplateSearchOptions";
import "./TemplateHistorySearchBar.scss";

interface HistorySearchProps {
    shouldRender: boolean,
    className?: string,
    templateType: ExcelTemplateTypes,
    isSearching: boolean,
    onSearchStart: (type: string, terms: string, templateStatus: TemplateStatus) => Promise<void>
}

const getDefaultSearchType = (template: string | ExcelTemplateTypes) => {
    switch (template) {
        case ExcelTemplateTypes.Supplier:
            return SupplierTemplateSearchTypes.VendorNumber.toString();
        //case ExcelTemplateTypes:Extension:
        case ExcelTemplateTypes.Fixed:
        case ExcelTemplateTypes.FloatItem:
        case ExcelTemplateTypes.FloatProductLine:
        default:
            return "";
    }
}

export default function TemplateHistorySearchBar(props: HistorySearchProps) {
    const { shouldRender, className, templateType, isSearching, onSearchStart } = props;

    const [searchTerms, setSearchTerms] = useState<string>(""),
          [searchType, setSearchType] = useState<string>(""),
          [isTransitioningOptions, setIsTransitioningOptions] = useState<boolean>(false),
          displaySearchOptions = useRef<ExcelTemplateTypes>(templateType);

    const { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender);

    //Transition timing handler for options selections
    useEffect(() => {
        let timer: NodeJS.Timeout,
            innerTimer: NodeJS.Timeout;
        if (templateType !== displaySearchOptions.current) {
            setIsTransitioningOptions(true);
            timer = setTimeout(() => {
                displaySearchOptions.current = templateType; 
                setSearchType(getDefaultSearchType(templateType));
                innerTimer = setTimeout(() => {
                    setIsTransitioningOptions(false);
                }, 225);
            }, 425);
        }
        return () => {
            clearTimeout(timer);
            clearTimeout(innerTimer);
        };
    }, [templateType, displaySearchOptions]);

    return (isNotTransitionedOut && 
        <div className={`${className ?? ""} historySearchBar ${shouldRender && isActive ? "" : "hidden"}`}>

            <div className="searchTextEntry">
                <input id="searchTermInput"
                       type="text" 
                       value={searchTerms} 
                       placeholder="Search for..." 
                       onChange={(e) => setSearchTerms(e.target.value)}/>
                <span className="searchHelpText">You can use commas to search for multiple values.</span>

                <TemplateButton className="templateButtonSearchHistory" 
                                text="SEARCH" 
                                btnIcon={faMagnifyingGlassPlay}
                                disabled={searchTerms === "" || isSearching}
                                onClick={async () => await onSearchStart(searchType, searchTerms, TemplateStatus.Any)} />
            </div>

            <div className={`searchOptions ${templateType === ExcelTemplateTypes.Supplier ? "supplierTemplate" : ""} ` + 
                            `${isTransitioningOptions ? "hidden" : ""}`}>
                <label id="searchByLabel" className={`${isTransitioningOptions ? "hidden" : ""}`}>
                    {"New template search by..."}
                </label>
                <div className="searchOptionsInputs">
                    {(templateType === ExcelTemplateTypes.Supplier && 
                        <SupplierPricingTemplateSearchOptions searchType={searchType} 
                                                              updateHandler={setSearchType}
                                                              selectedTemplateType={displaySearchOptions.current} />
                    )}
                </div>
            </div>
        </div>
    );
}