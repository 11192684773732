import { SupplierPricingExcelTemplate, 
         SupplierPricingExcelDataRow,  
         SupplierPricingExcelBracketData,
         BracketModelPropInfo, 
         SupplierPricingExcelApiValidationRequestData,
         SupplierPricingExcelApiValidationRequestData_Default, 
         SupplierPricingExcelApiValidationResponse,
         SupplierPricingExcelApiValidationRequest,
         ClientValidationResult,  
         PricingExcelApiResult,
         ExcelPropertyInfo,
         DateDetails,
         DateMetadata,
         ValidationService, 
         ExcelViewerErrorData } from "types";
import { ValidPriceDateTypes, ValidUnitsOfMeasure, SupplierPricingExcelColumnHeaders, SupplierTemplateApiResponseKeys } from "enums";
import { ClientValidationError, ClientValidationErrorType } from "errors";
import { makeDeepCopy, commonRegex } from "utils";
import { useSecurityService, useDateCheckerService, useExcelValidationHelpers } from "services";
import axios, { AxiosError, AxiosRequestConfig } from "axios";


export function useSupplierPricingTemplateValidationService(id: string, fileName: string, changeNotes: string, postValidationCallback?: (args: any) => void): ValidationService {

    let excelViewerErrorData: ExcelViewerErrorData[] = [];

    const { checkValidDay, 
            checkValidMonth, 
            checkValidYear, 
            checkIsLeapYear, 
            checkAllowedDayRange } = useDateCheckerService();

    const { checkRequiredField: checkAgainstEnum, 
            checkExcelHeaders,
            trimValuesLeadingAndTrailingWhitespace,
            standardizePropertyNames } = useExcelValidationHelpers();

    const { getAuthHeader, getUserEmail } = useSecurityService();
    
    const apiUrl = process.env.REACT_APP_SALES_PRICINGIMPORT_SUPPLIER_PRICING_VALIDATION_API,
          maxPricingDecimalPlaces = 4,
          regex = { bracketDefinition: /^pricebrkt(?<number>\d+)$/i,
                    bracketProperty: /^price_(?<number>\d+)$/i,
                    dateFormat: commonRegex.dateFormats.yyyymmdd,
                    gtinFormat: /^\d{14}$/,
                    hasNonNumeric: commonRegex.hasNonPositiveNumeric,
                    aboveMaxPrecision: commonRegex.maxDecimalPrecision(maxPricingDecimalPlaces),
                    hasSpaces: commonRegex.hasSpaces };


    //Sends a POST request containing uploaded and client-side validated excel data to Sales.PricingImport
    const postToValidationApi = async (clientValidatedExcel: SupplierPricingExcelDataRow[], id: string, changeNotes: string, allowIncompleteProductLines: boolean = false) => {
        const outboundModel = await createRequestModel(clientValidatedExcel, id, changeNotes, allowIncompleteProductLines);
        console.log("POST to validation API - COSMOS Id: " + id)
        try {
            const config: AxiosRequestConfig = { headers: await getAuthHeader() },
                  response = await axios.post(`${apiUrl}`, outboundModel, config),
                  status = response.status;
            return { response: response as SupplierPricingExcelApiValidationResponse, 
                     postError: "",
                     statusCode: status } as PricingExcelApiResult;
        } catch(ex: any) {
            const error = ex as AxiosError,
                  status = error.response?.status ?? 0;
            return { response: error.response as SupplierPricingExcelApiValidationResponse, 
                     postError: error.message ? error.message : "Error posting to validation API.",
                     statusCode: status } as PricingExcelApiResult;
        }
    }

    //Strips spaces and lower case letters out of Excel template property names, for 
    //fault tolerance and back compatability with templates that used spaces in the headers
    const standardizeExcelPropertyNameFormat = (rawExcel: any[]) => standardizePropertyNames<SupplierPricingExcelTemplate>(rawExcel);


    //Converts the raw excel upload to a validation model, doing client-side checks along the way.  Reports any errors encountered.
    const doClientSideValidation = async (rawExcel: SupplierPricingExcelTemplate[] | null, 
                                          skipNumHeaders: number = 2, 
                                          maxBrackets: number = 10) => {

        const excel = rawExcel ? trimValuesLeadingAndTrailingWhitespace<SupplierPricingExcelTemplate>(rawExcel) : rawExcel,
              postValidationModel: SupplierPricingExcelDataRow[] = [],
              errorList: ExcelViewerErrorData[] = [],
              errorPanelText: string[] = [],
              validUoms: string[] = Object.keys(ValidUnitsOfMeasure),
              validPriceDateTypes: string[] = Object.keys(ValidPriceDateTypes);
        let hasLoggedColumnError: boolean = false,
            errText: string = "";

        excelViewerErrorData = [];
        const extraHeaderValidation = () => {
            //This is checking the rows we're considering "headers" to see if they contain actual data, in case 
            //the headers were deleted or something.  It probably won't catch every possible mistake, but that's ok.
            if (!excel) return;
            for(let i = 0; i < skipNumHeaders; i++) {
                if(excel[i]["VENDOR#"] || excel[i]["VENDORNAME"] || excel[i]["PRODUCTDESCRIPTION"])
                {
                    throw new ClientValidationError(ClientValidationErrorType.BadExcelHeaders, 
                                                    "Excel data appears to have non-standard column headers.", 
                                                    null);
                }
            }
        };
        checkExcelHeaders(excel, skipNumHeaders, extraHeaderValidation);

        //Loop through rows of data, skipping the header rows
        for(let i = skipNumHeaders; i < excel!.length; i++) {
            const rowModel = {} as SupplierPricingExcelDataRow,
                  innerArray: SupplierPricingExcelBracketData[] = [],
                  currentRow = excel![i] as SupplierPricingExcelTemplate,
                  properties = Object.getOwnPropertyNames(currentRow);
            let skipThisDateValidation: boolean = false;
            
            //Loop through columns in row
            for(const aKey of properties) {
                const prop = aKey as keyof SupplierPricingExcelTemplate,
                      bracketDef = prop.match(regex.bracketDefinition),
                      bracketProp = prop.match(regex.bracketProperty),
                      thisCellValue = `${currentRow[prop]}`.trim() ?? "",
                      dataRow = i + skipNumHeaders;

                //Properties like "PRICEBRKT5"
                if (bracketDef?.groups?.number) {
                    const idNumber = parseInt(bracketDef.groups.number),
                          info = { key: "quantityDescription",
                                   value: thisCellValue,
                                   bracketNumber: idNumber,
                                   rowNumber: dataRow } as BracketModelPropInfo;
                          addOrModifyPriceBracketModel(innerArray, info, errorList);
                } 
                //Properties like "PRICE_8"
                else if (bracketProp?.groups?.number) {
                    const propNumber = parseInt(bracketProp.groups.number),
                    //Odd numbers are ID, even are price
                          propKey = (propNumber % 2 !== 0) ? "id" : "price",
                    //"PRICE_1" and "PRICE_2" should map to bracket 1, etc.
                          associatedBracketNum = Math.floor((propNumber + 1) / 2),
                          info = { key: propKey,
                                   value: thisCellValue,
                                   bracketNumber: associatedBracketNum,
                                   rowNumber: dataRow } as BracketModelPropInfo;
                          addOrModifyPriceBracketModel(innerArray, info, errorList);
                }
                //Mappings for any other properties
                else {
                    const propNameKey = prop as keyof typeof SupplierPricingExcelColumnHeaders,
                          propColumnName = `column "${SupplierPricingExcelColumnHeaders[propNameKey]}"`,
                          checkFieldProps = { propName: propNameKey,
                                              value: thisCellValue,
                                              dataRow: dataRow,
                                              log: errorList } as ExcelPropertyInfo;
                    switch(prop) {
                        case "PRICE":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                rowModel.priceListNumber = thisCellValue;
                            }
                            break;

                        case "VENDOR#":
                            rowModel.vendorNumber = thisCellValue ?? "";
                            break;

                        case "VENDORNAME":
                            rowModel.vendorName = thisCellValue ?? "";
                            break;

                         //This is required
                        case "PRICEBEGIN":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                if (regex.dateFormat.test(thisCellValue)) {
                                    rowModel.priceBeginDate = thisCellValue;
                                    break;
                                } else {
                                    errText = `Value "${thisCellValue}" for ${propColumnName} is not a valid date of form YYYYMMDD.`;
                                    errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                                }
                            }
                            //This flag setter is only reachable if this is empty or not a valid format, in which 
                            //case we've sent an error already.  This means we're ok to skip range checks, 
                            //which will obviously fail, and suppress any additional, less relevant 
                            //error messages for the same cell.
                            skipThisDateValidation = true;
                            break;

                        //This is allowed to be blank (and will be, often)
                        case "PRICEEND":
                            if (!thisCellValue || regex.dateFormat.test(thisCellValue)) {
                                rowModel.priceEndDate = thisCellValue ?? "";
                                break;
                            }
                            else {
                                errText = `Value "${thisCellValue}" for ${propColumnName} is not a valid date of form YYYYMMDD.`;
                                errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                            }
                            //We don't need to set the skip flag here, because a) we still want checks to run on a 
                            //valid-looking begin date and b) by not assigning a value to the row model in this block, 
                            //any further end date checks and message writes should be skipped anyway.
                            break;

                        case "PRICEDATETYPE":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                const upperCaseDateTypes = validPriceDateTypes.map(x => x.toUpperCase())
                                                                              .filter(x => isNaN(Number(x))),
                                      thisPriceDateType = thisCellValue.toUpperCase();

                                if (upperCaseDateTypes.includes(thisPriceDateType)) {
                                    rowModel.priceDateType = thisCellValue;
                                    break;
                                } else {
                                    errText = `Invalid value of "${thisPriceDateType}" for ${propColumnName}, not a recognized ` + 
                                              `price date type.  Valid options: ${upperCaseDateTypes.join(", ")}`;
                                    errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                                }
                            }
                            break;

                        case "REGION":
                            rowModel.region = thisCellValue ?? "";
                            break;

                        case "SKU/":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                rowModel.sku = thisCellValue;
                            }
                            break;

                        case "MASTERPACK":
                            if (!thisCellValue || regex.gtinFormat.test(thisCellValue)) {
                                rowModel.masterPackGtin = thisCellValue ?? "";
                            } else {
                                errText = `Invalid value "${thisCellValue}" for ${propColumnName},` + 
                                          ` a valid GTIN must be a 14-digit number.`;
                                errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                            }
                            break;

                        case "PRODUCTDESCRIPTION":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                rowModel.productDescription = thisCellValue;
                            }
                            break;

                        case "PRICEUOM":
                            if (checkIfRequiredFieldIsNotEmpty(checkFieldProps)) {
                                const upperCaseUoms = validUoms.map(x => x.toUpperCase())
                                                               .filter(x => isNaN(Number(x))),
                                      thisUomValue = thisCellValue.toUpperCase();

                                if (upperCaseUoms.includes(thisUomValue)) {
                                    rowModel.uom = thisCellValue;
                                    break;
                                } else {
                                    errText = `Invalid value "${thisUomValue}" for ${propColumnName}, not a recognized ` + 
                                              `unit of measure.  Valid options: ${upperCaseUoms.join(", ")}`;
                                    errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                                }
                            }
                            break;

                        case "PRODUCT":
                            if (thisCellValue && regex.hasNonNumeric.test(thisCellValue)) {
                                errText = `Invalid value "${thisCellValue}" for ${propColumnName}, ` +
                                          `cannot contain non-numeric characters.`;
                                errorList.push(new ExcelViewerErrorData(dataRow, propNameKey, [errText]));
                            } else {
                                rowModel.productLine = thisCellValue ?? "";
                            }
                            break;

                        default:
                            errText = `Unable to map ${prop} for request, reason: ` + 
                                      `does not match any known column headers.  Check that column headers ` +
                                      `(e.g. PRICEUOM) match those of the sample Excel template.`;   
                            excelViewerErrorData.push(new ExcelViewerErrorData(dataRow, prop, [errText]));
                            break;
                    }
                }
            }

            //Some final checks for this row after looping through properties
            rowModel.brackets = innerArray;
            const rowNumber = i + skipNumHeaders;
            if (rowModel.brackets.length > maxBrackets && !hasLoggedColumnError) {
                errorPanelText.unshift(`Template - Maximum number of pricing brackets exceeded ` + 
                                       `(data contains ${innerArray.length}, max is ${maxBrackets}).`);
                hasLoggedColumnError = true;
            } 
            else {
                for(const bracketData of rowModel.brackets) {
                    if (!bracketData.bracketNumber){
                        errText =  `Error parsing pricing brackets (data contained a bracket without a number).`;
                        errorList.push(new ExcelViewerErrorData(rowNumber, "", [errText]));
                    }
                    else if (bracketData.bracketNumber > maxBrackets && !hasLoggedColumnError) {
                        errText = `Error parsing pricing brackets (data contains ` + 
                                  `a bracket numbered ${bracketData.bracketNumber}, only 1-${maxBrackets} allowed).`;
                        errorList.push(new ExcelViewerErrorData(rowNumber, "", [errText]));
                    }
                }
            }
            if (!skipThisDateValidation) {
                await doDateValidation(errorList, rowModel, rowNumber);
            }
            postValidationModel.push(rowModel);
        }

        //When we're done looping through rows
        if (postValidationCallback) postValidationCallback([...excelViewerErrorData, ...errorList]);
        errorList.forEach(x => errorPanelText.push(`Data row ${x.row} - ${x.info.join(" | ")}`));
        return { requestData: postValidationModel as SupplierPricingExcelDataRow[], 
                 errorList: errorPanelText, 
                 errorCount: errorPanelText.length } as ClientValidationResult;
    };

    //Logs error on missing required field 
    const checkIfRequiredFieldIsNotEmpty = (props: ExcelPropertyInfo) => checkAgainstEnum(SupplierPricingExcelColumnHeaders, props);

    //Helper function for building the brackets property of a SupplierPricingExcelDataRow
    const addOrModifyPriceBracketModel = (priceBracketArray: SupplierPricingExcelBracketData[],
                                          propertyInfo: BracketModelPropInfo,
                                          errorList: ExcelViewerErrorData[]) => {
        const { key, value, bracketNumber, rowNumber } = propertyInfo,
                prop = key as keyof SupplierPricingExcelBracketData;
        let isEntryCreated: boolean = false,
            assigned: any = value,
            templateColumn: string,
            errText: string = "";
        
        //Check if this bracket already exists in priceBracketArray
        for(const bracket of priceBracketArray) {
            if(bracket?.bracketNumber === bracketNumber) {
                templateColumn = "";
                isEntryCreated = true;
                if(key === "price" && value != null) {
                    templateColumn = `PRICE_${2 * bracketNumber}`;
                    if(parseFloat(value) < 0) {
                        assigned = null;
                        errText = `Unable to map Price Bracket #${bracketNumber} ` + 
                                  `Price for request, reason: invalid value "${value}", negative numbers not allowed.`;
                        errorList.push(new ExcelViewerErrorData(rowNumber, templateColumn, [errText]));
                    } else if ( regex.hasNonNumeric.test(value) || (`${value}`).split('.', 3).length > 2 ) {
                        assigned = null;
                        errText = `Unable to map Price Bracket #${bracketNumber} ` + 
                                  `Price for request, reason: invalid value "${value}", this field can only contain ` +
                                  `characters 0-9 and a single decimal point.`;
                        errorList.push(new ExcelViewerErrorData(rowNumber, templateColumn, [errText]));
                    } else if (regex.aboveMaxPrecision.test(value)) {
                        assigned = null;
                        errText = `Unable to map Price Bracket #${bracketNumber} ` + 
                                  `Price for request, reason: invalid value "${value}", this field can have no more ` + 
                                  `than ${maxPricingDecimalPlaces} decimal places.`;
                        errorList.push(new ExcelViewerErrorData(rowNumber, templateColumn, [errText]));
                    }
                     else {
                        assigned = parseFloat(value);
                    }
                }
                bracket[prop] = assigned;
                break;
            }
        }

        //Create a new entry for priceBracketArray
        if(!isEntryCreated) {
            const newModel = {} as SupplierPricingExcelBracketData;
            newModel[prop] = assigned;
            newModel.bracketNumber = bracketNumber;
            priceBracketArray.push(newModel);
        }
    };


    //Assumes dates of format YYYYMMDD, checks for some simple errors before we send data to API for full validation
    const doDateValidation = async (errors: ExcelViewerErrorData[], model: SupplierPricingExcelDataRow, rowNumber: number) => {
        const format: RegExp = regex.dateFormat,
              begin = model.priceBeginDate?.match(format),
              end = model.priceEndDate?.match(format),
              bothDateColumns = `${SupplierTemplateApiResponseKeys.PRICEBEGINDATE}, ${SupplierTemplateApiResponseKeys.PRICEENDDATE}`;

        const beginDate = { year: parseInt(begin?.groups?.year ?? ""),
                            month: parseInt(begin?.groups?.month ?? ""),
                            day: parseInt(begin?.groups?.day ?? "") } as DateDetails,
              endDate =   { year: parseInt(end?.groups?.year ?? ""),
                            month: parseInt(end?.groups?.month ?? ""),
                            day: parseInt(end?.groups?.day ?? "") } as DateDetails,

              beginDateMetadata = { fullDateString: model.priceBeginDate,
                                    date: beginDate,
                                    dateType: SupplierTemplateApiResponseKeys.PRICEBEGINDATE,
                                    dateColumnName: SupplierPricingExcelColumnHeaders.PRICEBEGIN,
                                    rowNumber: rowNumber } as DateMetadata,
              endDateMetadata =   { fullDateString: model.priceEndDate,
                                    date: endDate,
                                    dateType: SupplierTemplateApiResponseKeys.PRICEENDDATE,
                                    dateColumnName: SupplierPricingExcelColumnHeaders.PRICEEND,
                                    rowNumber: rowNumber } as DateMetadata;

        const isSameYear = beginDate.year === endDate.year;

        const flags = { sameYear: isSameYear,
                        sameMonth: (isSameYear && beginDate.month === endDate.month),
                        invalidMonth: false,
                        invalidYear: false,
                        invalidDay: false,
                        badFormat: false };
        
        let errText: string = "";

        //priceBeginDate checks
        flags.invalidYear = !checkValidYear(beginDateMetadata, errors);
        flags.invalidMonth = !checkValidMonth(beginDateMetadata, errors);
        flags.invalidDay = !checkValidDay(beginDateMetadata, errors, flags.invalidMonth, flags.invalidYear);
        flags.badFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;

        //priceEndDate checks, this field is allowed to be blank
        if (model.priceEndDate) {
            flags.invalidYear = !checkValidYear(endDateMetadata, errors);
            flags.invalidMonth = !checkValidMonth(endDateMetadata, errors);
            flags.invalidDay = !checkValidDay(endDateMetadata, errors, flags.invalidMonth, flags.invalidYear);
            if (!flags.badFormat) flags.badFormat = flags.invalidDay || flags.invalidYear || flags.invalidMonth;
    
            //date range checks
            if( (flags.sameYear && beginDate.month > endDate.month)
             || (flags.sameMonth && beginDate.day > endDate.day)
             || (endDate.year < beginDate.year) ) {
                errText = `Invalid date range of "${model.priceBeginDate}" - "${model.priceEndDate}", ` + 
                          `reason: end date is before begin date.`;
                errors.push(new ExcelViewerErrorData(rowNumber, bothDateColumns, [errText]));
            }
        }

        if (flags.badFormat) {
            errText = `Ensure that all dates are in the correct format: YYYYMMDD`;
            errors.push(new ExcelViewerErrorData(rowNumber, bothDateColumns, [errText]));
        }
    };


    //After client-side validation, Converts excel data to an outbound API request
    const createRequestModel = async (clientValidatedExcel: SupplierPricingExcelDataRow[], id: string, changeNotes: string, allowIncompleteProductLines: boolean) => {
        const requestArray: SupplierPricingExcelApiValidationRequestData[] = [];
        for(const row of clientValidatedExcel) {
            const requestRow: any = makeDeepCopy (SupplierPricingExcelApiValidationRequestData_Default);

            requestRow.PriceListNumber = row.priceListNumber ?? "";
            requestRow.VendorName = row.vendorName ?? "";
            requestRow.VendorNumber = row.vendorNumber ? row.vendorNumber : 0;

            requestRow.PriceBeginDate = row.priceBeginDate ?? "";
            requestRow.PriceEndDate = row.priceEndDate ?? "";
            requestRow.PriceDateType = row.priceDateType ?? "";

            requestRow.RegionCovered = row.region ?? "";
            requestRow.SKUorModel = row.sku ?? "";
            requestRow.GTIN = row.masterPackGtin ?? "";

            requestRow.ProductDescription = row.productDescription ?? "";
            requestRow.PriceUnitOfMeasure = row.uom ?? "";
            requestRow.ProductLine = row.productLine ?? "";

            if(row.brackets) {
                for(const bracketData of row.brackets) {
                    const num = bracketData.bracketNumber;
                    if(num) {
                        requestRow[`PriceBracket${num}ID`] = bracketData.id ?? "";
                        requestRow[`PriceBracket${num}Price`] = bracketData.price ? bracketData.price : null;
                        requestRow[`PriceBracket${num}QtyDescription`] = bracketData.quantityDescription ?? "";
                    }
                }
            }
            requestArray.push(requestRow as SupplierPricingExcelApiValidationRequestData);
        }
        return { CosmosGUID: id, 
                 TemplateFileName: fileName, 
                 SubmitterEmail: getUserEmail(),
                 ChangeNotes: changeNotes,
                 SupplierPricingTemplate: requestArray, 
                 AllowIncompleteProductLines: allowIncompleteProductLines } as SupplierPricingExcelApiValidationRequest;
    }

    //Exports
    const testingExports = {
        addOrModifyPriceBracketModel,
        doDateValidation,
        checkExcelHeaders,
        checkAllowedDayRange,
        createRequestModel,
        checkIsLeapYear
    };

    return {
        doClientSideValidation, 
        postToValidationApi,
        standardizeExcelPropertyNameFormat,
        testingExports
    }
}
