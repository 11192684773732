import { ExcelTemplateTypes, SupplierTemplateSearchTypes } from "enums";
import { useInOutTransition } from "hooks";
import SearchRadioInput from "./SearchRadioInput";

interface Props {
    searchType: string,
    updateHandler: (x: SupplierTemplateSearchTypes) => void,
    selectedTemplateType: ExcelTemplateTypes
}

export default function SupplierPricingTemplateSearchOptions(props: Props) {
    const { searchType, updateHandler, selectedTemplateType } = props,
          shouldRender = selectedTemplateType === ExcelTemplateTypes.Supplier,
          { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender, 0, 275);
    return (isNotTransitionedOut && 
        <>
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="vendorName" 
                              labelText="Vendor Name" 
                              checkedCondition={searchType === SupplierTemplateSearchTypes.VendorName}
                              onClick={() => updateHandler(SupplierTemplateSearchTypes.VendorName)} />

            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="vendorNumber" 
                              labelText="Vendor Number" 
                              checkedCondition={searchType === SupplierTemplateSearchTypes.VendorNumber}
                              onClick={() => updateHandler(SupplierTemplateSearchTypes.VendorNumber)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineName" 
                              labelText="Product Line Name" 
                              checkedCondition={searchType === SupplierTemplateSearchTypes.ProductLineName}
                              onClick={() => updateHandler(SupplierTemplateSearchTypes.ProductLineName)} />
            
            <SearchRadioInput className={shouldRender && isActive ? "" : "hidden"}
                              id="productLineNumber" 
                              labelText="Product Line Number" 
                              checkedCondition={searchType === SupplierTemplateSearchTypes.ProductLineNumber}
                              onClick={() => updateHandler(SupplierTemplateSearchTypes.ProductLineNumber)} />
        </>
    );
}