export const getEnumKeysExcept = <TEnum extends object>(keyEnum: TEnum, exceptions: string[]) => {
    const allKeys = getEnumKeys(keyEnum);
    const values: string[] = Object.values(keyEnum)
                                   .map(x => x.toString());
    const validExceptions = exceptions.filter(ex => values.indexOf(ex) !== -1);
    return validExceptions && validExceptions.length ? allKeys.filter(key => exceptions.findIndex(type => type === keyEnum[key]) === -1)
                                                     : allKeys;
}

export const getEnumKeys = <TEnum extends object>(keyEnum: TEnum) => {
    const allKeys = Object.keys(keyEnum)
                          .map(key => key as keyof typeof keyEnum);
    return allKeys;
}

export const getEnumValues = <TEnum extends object>(keyEnum: TEnum) => {
    return getEnumKeys(keyEnum).map(x => keyEnum[x]);
}
