import { ExcelTemplateProps, TemplateConstants } from "types";

export const templateConfig = {
    supplierPricingTemplate: {
        //There are actually 3 header rows, but the first row is read as keys
        numHeaderRows: 2,
        maxBrackets: 10,
    } as TemplateConstants,

    floatItemPricingTemplate: {
        //First and only header row is read as keys
        numHeaderRows: 0
    } as TemplateConstants,

    floatProductLinePricingTemplate: {
        //First and only header row is read as keys
        numHeaderRows: 0
    } as TemplateConstants,

    fixedPricingTemplate: {
        //First and only header row is read as keys
        numHeaderRows: 0
    } as TemplateConstants,

    //extensionPricingTemplate: {
    //    numHeaderRows: 0
    //} as TemplateConstants
} as ExcelTemplateProps;
