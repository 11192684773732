import './ViewTemplateTable.scss';
import { useExcelData } from "components/Contexts/ExcelDataContext";
import { ExcelKeyValuePairs } from "components/Contexts/ExcelKeyValuePairs";
import { useCallback, useState } from "react";
import { ExcelViewerErrorData } from "types";
import { stripCaseAndSpaces } from "utils";
import { ExcelTemplateTypes, 
         FloatItemTemplateApiResponseKeys, 
         FloatProductLineTemplateApiResponseKeys,
        SupplierTemplateApiResponseKeys,
        SpecialFixedTemplateApiResponseKeys } from 'enums';
import Cell from '../TemplateOptions/Cell';

interface Props {
    templateType: ExcelTemplateTypes,
    numHeaders?: number
}

interface CellProps {
    kvps: ExcelKeyValuePairs, 
    key: string, 
    rowNumber: number, 
    matchingErrors: ExcelViewerErrorData[] | null | undefined,
    isHeader: boolean
}

//Maps keys in API response errors to the column keys of the uploaded excel template.
//The comma is not a typo, required here to distinguish between a TypeScript generic and a JSX element.
const mapColumns = <TApiResponseKeys extends object,>(keyEnum: TApiResponseKeys, apiErrorKeys: string[]) => {
    const columns: string[] = [];
    for (const apiErrorKey of apiErrorKeys) {
        const validKey = apiErrorKey in keyEnum,
              column = validKey ? `${keyEnum[apiErrorKey as keyof typeof keyEnum]}`
                                : apiErrorKey;
        columns.push(column);
    }
    return columns;
}


export const ViewTemplateTable = ({templateType, numHeaders = 0}: Props) => {
    const { excelContext, errorContext } = useExcelData(),
          { excelData } = excelContext,
          { excelErrors } = errorContext,
            errorList = excelErrors as ExcelViewerErrorData[],
            rowNumberKey = "RowNumber";

    // State to keep track of current editable data that will be revised
    const [editableData, setEditableData] = useState(excelData);

    // Function that handles updates to the excel template
    const handleInputChange = useCallback((rowIndex: number, key: string, value: string) => {
        setEditableData((prevData: any) => {
            const newData = [...prevData];
            newData[rowIndex][key] = value;
            return newData;
        });
    }, [setEditableData]);

    //Reverse-mapping API response error keys to template columns
    const mapKeysToViewerColumns = useCallback((compositeKey: string[] | null | undefined): string[] => {
        if (!compositeKey || compositeKey.length === 0) return [""];
        else {
            const apiErrorKeys = compositeKey?.map(x => stripCaseAndSpaces(x)) ?? [];
            switch (templateType) {
                case ExcelTemplateTypes.Supplier:
                    return mapColumns(SupplierTemplateApiResponseKeys, apiErrorKeys);
                case ExcelTemplateTypes.FloatItem:
                    if (apiErrorKeys.includes("EMPLOYEENUMBER")) apiErrorKeys.unshift("EMPLYEENUMBER");
                    return mapColumns(FloatItemTemplateApiResponseKeys, apiErrorKeys);
                case ExcelTemplateTypes.FloatProductLine:
                    return mapColumns(FloatProductLineTemplateApiResponseKeys, apiErrorKeys);
                case ExcelTemplateTypes.Fixed:
                    if (apiErrorKeys.includes("EMPLOYEENUMBER")) apiErrorKeys.unshift("EMPLYEENUMBER");
                    return mapColumns(SpecialFixedTemplateApiResponseKeys, apiErrorKeys);
                //case ExcelTemplateTypes.Extension:
                default:
                    return apiErrorKeys;
            }
        }
    }, [templateType]);


    //Populates row data, highlights individual cells if both row and column are provided in the error list
    const generateDataCell = useCallback((cellProps: CellProps) => {
        const { key, rowNumber, matchingErrors, isHeader } = cellProps,
            column = stripCaseAndSpaces(key),
            highlighted: boolean = (matchingErrors != null && matchingErrors.length > 0),
            errorDetails = matchingErrors?.filter((x) => mapKeysToViewerColumns(x.column.split(',')).includes(column)) ?? [],
            hasCellError: boolean = (errorDetails != null && errorDetails.length > 0),
            emphasizeThisCell: boolean = (highlighted && hasCellError),
            mouseoverText = hasCellError ? errorDetails.map(x => x.info.join("\n")).join("\n") : "";

        let value: string;
        if (key !== rowNumberKey) {
            value = editableData[rowNumber - 2][key]?.toString() || "";
            //console.log("rowNumber: " + rowNumber + " | key: " + key + " | value : " + value);
        } else {
            value = rowNumber - 1 > numHeaders ? rowNumber.toString() : "";
        }

        return (
            <Cell
                key={key}
                value={value}
                rowIndex={rowNumber - 2}
                columnKey={key}
                onChange={handleInputChange}
                isHeader={key === rowNumberKey || isHeader}
                hasError={emphasizeThisCell}
                mouseoverText={mouseoverText}
            />
        );
    }, [numHeaders, mapKeysToViewerColumns, editableData, handleInputChange]); 


    //Highlights rows with errors on errorList change
    const generateDataRow = useCallback((excelRow: ExcelKeyValuePairs, index: number) => {
        const rowNumber = index + 2,
              matches = (() => {
                  if (errorList && errorList.length > 0) {
                      return errorList.filter((err: ExcelViewerErrorData) => err.row === rowNumber)
                  }
                  else return null;
              })();
        const hasMatches = (matches && matches.length > 0),
              firstMatch = hasMatches ? matches[0] : null,
              highlighted: boolean = (firstMatch != null && firstMatch?.row === rowNumber);

        return (
            <tr key={index} 
                className={`${index < numHeaders ? `excelHeaderRow` : "excelDataRow"} ${highlighted ? 'highlighted' : ""}`}>
                {[rowNumberKey, ...Object.keys(excelRow)].map(
                    (key) => {
                        const props = { kvps: excelRow, 
                                        key: key, 
                                        rowNumber: rowNumber,
                                        matchingErrors: hasMatches ? matches : [],
                                        isHeader: index < numHeaders
                                        } as CellProps;
                        return generateDataCell(props);
                    }
                )}
            </tr>
        );
    }, [errorList, numHeaders, generateDataCell]);


    return (
        <table className='viewTable'>
            <thead>
                <tr>
                    <th key={rowNumberKey}>ROW#</th>
                    {Object.keys(excelData[0]).map((key) => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {excelData.map((rowExcelData: ExcelKeyValuePairs, index: number) => generateDataRow(rowExcelData, index))}
            </tbody>
        </table>
    );
}
