import { Helmet } from 'react-helmet-async';
import { Suspense, useMemo, useReducer, useState } from 'react';
import { ExcelTemplateTypes, SupplierTemplateSearchTypes, TemplateStatus } from 'enums';
import { useCosmosExcelConverterService, useTemplateHistoryService } from 'services';
import { TemplateHistoryErrorPanel, TemplateHistoryHeader, 
         TemplateHistorySearchBar, 
         TemplateHistorySearchResultsTable, 
         TemplateSelector } from 'components';
import "./TemplateHistoryPage.scss"
import { PricingTemplateHistoryApiResult, SupplierPricingTemplateHistorySearch } from 'types';
import { getEnumValues, prettyStringify } from 'utils';

interface SearchResultsUpdate {
    type: "reset" | "update" | "downloadError" | "dismissErrors",
    apiResult?: PricingTemplateHistoryApiResult
}

interface TemplateHistorySearchResults {
    result: any[],
    errors: string[],
    actionMessage: string,
    emptyResult?: boolean
}

//Reducer function
const updateSearchResults = (state: TemplateHistorySearchResults, params: SearchResultsUpdate): TemplateHistorySearchResults => {
    const { type, apiResult } = params,
          defaultResult = { result: [], errors: [], actionMessage: "", emptyResult: false };
    switch (type) {
        case "reset":
            return defaultResult;
        case "downloadError":
            const downloadErrorResult = { result: state.result ,
                                          errors: [apiResult?.error ?? ""], 
                                          actionMessage: "If the problem persists, please report this issue." };
            if (apiResult?.statusCode) 
                downloadErrorResult.errors.push(`Response Code: ${apiResult.statusCode}`);
            return downloadErrorResult;
        case "dismissErrors":
            return { ...defaultResult, result: state.result };
        case "update":
            const statusCode = apiResult?.statusCode ?? 0;
            switch (true) {
                case statusCode >= 200 && statusCode < 300 && statusCode !== 204:
                    return { ...defaultResult, result: apiResult?.response ?? [] };
                case statusCode === 204:
                    return { ...defaultResult, emptyResult: true };
                case statusCode >= 400 && statusCode < 500:
                    return { result: defaultResult.result, 
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`], 
                             actionMessage: "If the problem persists, please report this issue." };
                case statusCode === 502:
                    return { result: defaultResult.result, 
                             errors: [apiResult?.error ?? "502 Bad Gateway", 
                                      "This error usually means that PriceFx is down, but also may indicate a problem " + 
                                      "with your local network connection."], 
                            actionMessage: "Please try again later." };
                case statusCode === 503:
                    return { result: defaultResult.result, 
                             errors: [apiResult?.error ?? "503 Service Unavailable"],
                             actionMessage: "Please try again later."};
                default:
                    return { result: defaultResult.result, 
                             errors: [apiResult?.error ?? `HTTP Error: ${statusCode}`],
                             actionMessage: "This problem may require developer attention, and should be reported." };
            }
    }
};

export default function TemplateHistoryPage() {  
    const [searchResults, dispatch] = useReducer(updateSearchResults, { result: [], errors: [], actionMessage: "" }),
          [templateType, setTemplateType] = useState<ExcelTemplateTypes>(ExcelTemplateTypes.None),
          [isSearching, setIsSearching] = useState<boolean>(false),
          renderSearchResultSection = (searchResults.emptyResult || searchResults.result?.length > 0) && !isSearching;

    const { getSupplierPricingTemplateHistory } = useTemplateHistoryService(),
          { convertSupplierPricingTemplate } = useCosmosExcelConverterService();

    const loadAnimation = useMemo(() => {
        return (
            <div className="templateLoaderContainer">
                <span className="templateLoader"></span>
            </div>
        );
    }, []);

    const handleSearch = async (searchType: string, terms: string, templateStatus: TemplateStatus) => {
        switch(templateType) {
            case ExcelTemplateTypes.Supplier: {
                console.log(`searchType: ${searchType}, terms: ${terms}, templateStatus: ${templateStatus}`)
                const searchByParam = getEnumValues(SupplierTemplateSearchTypes).find(x => x.toString() === searchType),
                      params = { searchType: searchByParam,
                                 searchTerms: terms.split(',').map(x => x.trim()),
                                 templateStatus: templateStatus } as SupplierPricingTemplateHistorySearch;
                      setIsSearching(true);
                      const apiResult = await getSupplierPricingTemplateHistory(params).catch(ex => {
                        throw Error(`useTemplateHistoryService.getSupplierPricingTemplateHistory: ` + 
                                    `params: ${prettyStringify(params)}, error: ${ex.toString()}`);
                      });
                      dispatch({ type: "update", apiResult: apiResult });
                      setIsSearching(false);
                }
                break;
            default:
                throw Error("Template history for this template type is not yet implemented.");
        }
    };

    const handleDownloadExcelDocument = async (templateId: string) => {
        const params = { searchType: SupplierTemplateSearchTypes.TemplateId,
                         searchTerms: [templateId],
                         templateStatus: TemplateStatus.Any } as SupplierPricingTemplateHistorySearch;
        const apiResult = await getSupplierPricingTemplateHistory(params).catch(ex => {
            throw Error(`useTemplateHistoryService.getSupplierPricingTemplateHistory: ` + 
                        `params: ${prettyStringify(params)}, error: ${ex.toString()}`);
        });

        if (apiResult.error) {
            dispatch({ type: "downloadError",  apiResult: apiResult });
        } else if (apiResult.response?.length) {
            convertSupplierPricingTemplate(apiResult.response[0]);
        }
    };

    const handleTemplateTypeChange = (selection: ExcelTemplateTypes) => {
        dispatch({ type: "reset" });
        setIsSearching(false);
        //Delay to allow the result table to fade out, prevents a jarring transition
        setTimeout(() => setTemplateType(selection), 525);
    };

    return (
        <>
            <Helmet>
                <title>Pricing Portal | Template History</title>
            </Helmet>
            <div className="templateHistoryPage">
                <TemplateHistoryErrorPanel shouldRender={searchResults.errors?.length > 0} 
                                           content={searchResults.errors ?? []} 
                                           actionMessage={searchResults.actionMessage ?? ""} 
                                           dismissCallback={() => dispatch({ type: "dismissErrors" })}/>
                <div className="historyHeaderRow">
                    <div className="historyHeaderTextContainer">
                        <TemplateHistoryHeader templateType={templateType} searchInProgress={isSearching} hasSearchResult={searchResults.result?.length > 0} />
                    </div>
                    <TemplateSelector className="templateHistorySelect"
                                      selected={templateType}
                                      onTemplateChange={(x) => handleTemplateTypeChange(x)} />
                </div>
                <div className="historySearchBarContainer">
                    <TemplateHistorySearchBar shouldRender={templateType !== ExcelTemplateTypes.None} 
                                              templateType={templateType}
                                              isSearching={isSearching}
                                              onSearchStart={async (type, terms, templateStatus) => await handleSearch(type, terms, templateStatus)} />
                </div>
                {(isSearching ? (
                    loadAnimation
                    ) : (
                        <Suspense fallback={loadAnimation}>
                            <div className='historySearchResultsContainer'>
                                <TemplateHistorySearchResultsTable shouldRender={renderSearchResultSection}
                                                                   templateType={templateType}
                                                                   onDownload={handleDownloadExcelDocument}
                                                                   tableData={searchResults.result ?? []} />
                            </div>
                        </Suspense>
                    )
                )}
            </div>
        </>
    );
}
