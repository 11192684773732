import { Button } from "@progress/kendo-react-buttons";
import { MultiSelect, MultiSelectChangeEvent } from "@progress/kendo-react-dropdowns";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import { TemplateStatus } from "enums";
import { commonRegex, getEnumKeys } from "utils";
import "./TemplateHistoryStatusFilter.scss";

interface DateFilterProps {
    statusValues: string[]
    onFilterClear: () => void,
    onFilterChange: (value: string[]) => void
}

const statusFilters = getEnumKeys(TemplateStatus).filter(x => commonRegex.hasNonNumeric.test(x) && x.toLowerCase() !== "any");

export default function TemplateHistoryDateRangeFilter(props: DateFilterProps) {
    const { statusValues, onFilterClear, onFilterChange } = props;

    const onSelectionChange = (selection: string[]) => {
        if (selection.length) {
            onFilterChange(selection);
        } else {
            onFilterClear();
        }
    }

    return (
        <div className="statusFilter k-filtercell k-filtercell-operator">
            <MultiSelect data={statusFilters} 
                         onChange={(event: MultiSelectChangeEvent) => onSelectionChange([...event.value])}
                         value={statusValues}/>
            <Button title="Clear"
                    className="clearButton"
                    disabled={!statusValues.length}
                    onClick={() => onSelectionChange([])}>
                <SvgIcon icon={filterClearIcon} />
            </Button>
      </div>
    );
}
