import { commonRegex } from 'utils';

/* Formats the amount presented in a component as United States dollar currency. For example 
    30.23 will be displayed "$30.23.""
   -30.23 will be displayed as "-$30.23."" 
 */
export const formatMoney = (amount: number) =>  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
}).format(amount); 

export const stripSpaces = (str: string) => {
    const { hasSpaces } = commonRegex;
    hasSpaces.lastIndex = 0;
    return str.replace(hasSpaces, '').toUpperCase();
};

export const stripCaseAndSpaces = (str: string) => {
    return stripSpaces(str).toUpperCase();
};

export const prettyStringify = (json: any) => {
    return JSON.stringify(json, null, "\t");
};

export const longStringEllipsis = (str: string, threshold: number = 25): string => {
    if (threshold < 4) threshold = 4;
    return str.length <= threshold ? str : `${str.slice(0, threshold - 3)}...`;
};