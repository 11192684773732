export enum ClientValidationErrorType {
    Aggregate = "Aggregate",
    BadExcelHeaders = "BadExcelHeaders",
    NoData = "NoData",
    TypeMismatch = "TypeMismatch"
}

export class ClientValidationError extends Error {
    name: string;
    message: string;
    problems: string[];

    constructor(name: ClientValidationErrorType, message: string, problems: string[] | null) {
        super();
        this.name = `${name}`;
        this.message = message;
        this.problems = problems ?? [];
    }
}
