import "./TemplateHeaderSection.scss";
import { ExcelTemplateTypes } from 'enums';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { SpringConfig, useReducedMotion } from '@react-spring/web';
import { makeDeepCopy } from 'utils';

//FontAwesome icons
import { faChevronDoubleDown } from "@fortawesome/pro-duotone-svg-icons";
import { faFile } from "@fortawesome/sharp-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
    templateType: string
    hasUpload: boolean
}

const initialHeader = "Upload Your Template",
      //uploadAnother = "Upload another template.",  // Not currently used
      initialSection = "Select a template type from the dropdown to get started.",
      fadeCssClass = 'reducedMotionFade';

export default function TemplateHeaderSection({templateType, hasUpload}: Props) {

    const [headerText, setHeaderText] = useState<string | null>(initialHeader),
          [sectionText, setSectionText] = useState<string | null>(initialSection),
          initialized = useRef<boolean | null>(false);
    
    const reducedMotion = useReducedMotion();

    
    //Load-in delay - delay is to prevent transition animations from firing on load
    useEffect(() => {
        const timer = setTimeout(() => initialized.current = true, 200);
        return () => clearTimeout(timer);
    }, []);


    //Css toggle for alternate header text transition w/ reduced motion preference
    useEffect(() => {
        let fadeInTimer: NodeJS.Timeout, 
            fadeOutTimer: NodeJS.Timeout;
        if (!reducedMotion || !initialized?.current) return;
        else {
            const headerElement = document.querySelector(`#templateHeaderElementText.${fadeCssClass}`),
                  header = headerElement as HTMLElement;
            if (!headerText?.includes(templateType)) {
                header.classList.add('hidden');
            }
            fadeOutTimer = setTimeout(() => {
                fadeInTimer = setTimeout(() => {
                    header.classList.remove('hidden');
                }, 100);
            }, 100);
            return () => {
                if (fadeInTimer) clearTimeout(fadeInTimer);
                if (fadeOutTimer) clearTimeout(fadeOutTimer)
            }
        }
    }, [initialized, templateType, headerText, reducedMotion]);


    //Css toggle for alternate section text transition w/ reduced motion preference
    useEffect(() => {
        let fadeInTimer: NodeJS.Timeout, 
            fadeOutTimer: NodeJS.Timeout;
        if (!reducedMotion || !initialized?.current) return;
        else {
            const sectionElement = document.querySelector(`#templateSectionElementText.${fadeCssClass}`),
                  section = sectionElement as HTMLElement;
            if (sectionText === initialSection && hasUpload) {
                section.classList.add('hidden');
            }
            fadeOutTimer = setTimeout(() => {
                fadeInTimer = setTimeout(() => {
                    section.classList.remove('hidden');
                }, 100);
            }, 100);
        }
        return () => {
            if (fadeInTimer) clearTimeout(fadeInTimer);
            if (fadeOutTimer) clearTimeout(fadeOutTimer)
        }
    }, [hasUpload, sectionText, reducedMotion]);


    const hasTemplateTypeSelected = useCallback(() => {
        return !(templateType === ExcelTemplateTypes.None);
    }, [templateType]);


    //This prevents transition animations from playing on page load
    const getSpringConfig = useCallback((preset: SpringConfig = presets.gentle) => {
        const initial = makeDeepCopy(preset) as SpringConfig;
        if (!initialized.current) {
            initial.progress = 5;
            initial.duration = 0;
            return initial;
        }
        else return preset;
    }, [initialized])
    

    //Header element JSX change if user prefers reduced motion
    const headerElement = useMemo(() => {
        const config = getSpringConfig();
        if (reducedMotion) return (
            <section id="templateHeaderElementText" className={fadeCssClass}>
                {headerText}&nbsp;&nbsp;<FontAwesomeIcon id="uploadTemplateIcon" icon={faFile}/>
            </section>
        );
        else return (
            <TextTransition direction={"down"} 
                            delay={425} 
                            springConfig={config}>
                {headerText}&nbsp;&nbsp;<FontAwesomeIcon id="uploadTemplateIcon" icon={faFile}/>
            </TextTransition>
        );
    }, [reducedMotion, headerText, getSpringConfig]);


    //Section element JSX change if user prefers reduced motion
    const sectionChangingTextElement = useMemo(() => {
        const config = getSpringConfig(presets.slow);
        if (reducedMotion) return (
            <section id="templateSectionElementText" className={fadeCssClass}>
                {sectionText}  {hasUpload && <FontAwesomeIcon id="viewTemplateBelowIcon" icon={faChevronDoubleDown}/>}
            </section>
        );
        else return (
            <TextTransition direction={"down"} 
                            delay={750} 
                            springConfig={config}>
                {sectionText}  {hasUpload && <FontAwesomeIcon id="viewTemplateBelowIcon" icon={faChevronDoubleDown}/>}
            </TextTransition>
        );
    }, [reducedMotion, sectionText, getSpringConfig, hasUpload]);

    
    const updateSectionText = useCallback(() => {
        const text = `${ (hasTemplateTypeSelected() && hasUpload) ? "You can view your uploaded template below." 
                                                                  : initialSection }`;
        if (text !== sectionText) {
            setSectionText(text);
        }
    }, [sectionText, hasTemplateTypeSelected, hasUpload]);


    const updateHeaderText = useCallback(() => {
        const text = `Upload Your ${ hasTemplateTypeSelected() ? templateType 
                                                               : "Template" }`;
        if (headerText && !headerText.includes(text)) {
            setHeaderText(text);
        }
    }, [headerText, templateType, hasTemplateTypeSelected]);


    //Facilitates automatic resizing for the animated text transitions by 
    //wiping out the inline css and forcing it to regenerate on the next render
    const handleWindowResize = useCallback(() => 
    {
        const transitionElements = document.querySelectorAll('.text-transition');
        for (const element of transitionElements)
        {
            if (element != null) {
                element.setAttribute('style', "");
            }
        }
    }, []);


    //Handles adjustments on window resizing
    useEffect(() => {
        updateHeaderText();
        updateSectionText();
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize, updateSectionText, updateHeaderText]);



    return (
        <>
            <h2 id="uploadTemplateHeader" data-testid="templateHeaderSectionTitle">
                <span>
                    {headerElement}
                </span>
            </h2>
            <div id="uploadTemplateTextSection">
                <section data-testid="templateHeaderSectionLowerText" className="lowerText">
                    After filling out your Dot Price Excel form, use the upload button and submit your template.<br></br><br></br>
                    {sectionChangingTextElement}
                </section>
            </div>
        </>
    )
}
