export type SupplierPricingExcelTemplate = {
    PRICE: string | null,
    "VENDOR#": string | null,
    VENDORNAME: string | null,
    PRICEBEGIN: string | null,
    PRICEEND: string | null,
    PRICEDATETYPE: string | null,
    REGION: string | null,
    "SKU/": string | null,
    MASTERPACK: string | null,
    PRODUCTDESCRIPTION: string | null,
    PRICEUOM: string | null,
    PRICE_1: string | number | null,
    PRICE_2: string | number | null,
    PRICEBRKT1: string | null,
    PRICE_3: string | number | null,
    PRICE_4: string | number | null,
    PRICEBRKT2: string | null,
    PRICE_5: string | number | null,
    PRICE_6: string | number | null,
    PRICEBRKT3: string | null,
    PRICE_7: string | number | null,
    PRICE_8: string | number | null,
    PRICEBRKT4: string | null,
    PRICE_9: string | number | null,
    PRICE_10: string | number | null,
    PRICEBRKT5: string | null,
    PRICE_11: string | number | null,
    PRICE_12: string | number | null,
    PRICEBRKT6: string | null,
    PRICE_13: string | number | null,
    PRICE_14: string | number | null,
    PRICEBRKT7: string | null,
    PRICE_15: string | number | null,
    PRICE_16: string | number | null,
    PRICEBRKT8: string | null,
    PRICE_17: string | number | null,
    PRICE_18: string | number | null,
    PRICEBRKT9: string | null,
    PRICE_19: string | number | null,
    PRICE_20: string | number | null,
    PRICEBRKT10: string | null,
    PRODUCT: string | null
}

export const SupplierPricingExcelTemplate_Default = {
    PRICE: "",
    "VENDOR#": "",
    VENDORNAME: "",
    PRICEBEGIN: "",
    PRICEEND: "",
    PRICEDATETYPE: "",
    REGION: "",
    "SKU/": "",
    MASTERPACK: "",
    PRODUCTDESCRIPTION: "",
    PRICEUOM: "",
    PRICE_1: "",
    PRICE_2: "",
    PRICEBRKT1: "",
    PRICE_3: "",
    PRICE_4: "",
    PRICEBRKT2: "",
    PRICE_5: "",
    PRICE_6: "",
    PRICEBRKT3: "",
    PRICE_7: "",
    PRICE_8: "",
    PRICEBRKT4: "",
    PRICE_9: "",
    PRICE_10: "",
    PRICEBRKT5: "",
    PRICE_11: "",
    PRICE_12: "",
    PRICEBRKT6: "",
    PRICE_13: "",
    PRICE_14: "",
    PRICEBRKT7: "",
    PRICE_15: "",
    PRICE_16: "",
    PRICEBRKT8: "",
    PRICE_17: "",
    PRICE_18: "",
    PRICEBRKT9: "",
    PRICE_19: "",
    PRICE_20: "",
    PRICEBRKT10: "",
    PRODUCT: ""
} as SupplierPricingExcelTemplate;
