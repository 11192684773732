import TemplateButton from 'components/Buttons/TemplateButton';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useRef } from 'react';
import { useInOutTransition } from 'hooks';
import './TemplateHistoryErrorPanel.scss';

interface Props {
    shouldRender: boolean,
    content: string[],
    actionMessage: string,
    dismissCallback: () => void,
}

export default function TemplateHistoryErrorPanel({ shouldRender, content, actionMessage, dismissCallback }: Props) {
    const { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender),
          panelContent = useRef<string[] | null>(null);

    if (!isActive) {
        panelContent.current = content;
    }

    const localCopy: string[] = [...panelContent.current ?? []],
          title = localCopy.shift();

    return (isNotTransitionedOut && (
        <>
            <div className={`historyErrorsPanel ${shouldRender && isActive ? "" : "hidden"}`} 
                 data-testid="historyErrorsPanel">
                <p>{"Unable to retrieve history data for the specified search parameters." +
                    ((panelContent.current && panelContent.current.length > 0) ? "  See below for more details." : "")}
                </p>
                <div hidden={!panelContent.current || panelContent.current.length === 0}>
                    <p>{title}</p>
                    <ul>{localCopy.map((errorString, index) => <li key={`errorString${index}`}>{errorString}</li>)}</ul>
                </div>
                <p id="actionMessage">{actionMessage}</p>
                    <TemplateButton text="Close" btnIcon={faXmark} onClick={dismissCallback}></TemplateButton>
            </div>
        </>
    ));
}
