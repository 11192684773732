import { ExcelTemplateTypes } from 'enums';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import TextTransition, { presets } from 'react-text-transition';
import { SpringConfig, useReducedMotion } from '@react-spring/web';
import { makeDeepCopy } from 'utils';

//FontAwesome icons
import { faChevronDoubleDown } from "@fortawesome/pro-duotone-svg-icons";
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TemplateHistoryHeader.scss";

interface Props {
    templateType: string
    searchInProgress: boolean,
    hasSearchResult: boolean
}

const initialHeader = "Search Template History",
      initialSection = "Select a template type from the dropdown to get started.",
      fadeCssClass = 'reducedMotionFade';

export default function TemplateHistoryHeader({templateType, searchInProgress, hasSearchResult}: Props) {
    const [headerText, setHeaderText] = useState<string | null>(initialHeader),
          [sectionText, setSectionText] = useState<string | null>(initialSection),
          initialized = useRef<boolean | null>(false);
    
    const reducedMotion = useReducedMotion();

    //Load-in delay
    useEffect(() => {
        const timer = setTimeout(() => initialized.current = true, 200);
        return () => clearTimeout(timer);
    }, []);

    //This prevents transition animations from playing on page load
    const getSpringConfig = useCallback((preset: SpringConfig = presets.gentle) => {
        const initial = makeDeepCopy(preset) as SpringConfig;
        if (!initialized.current) {
            initial.progress = 5;
            initial.duration = 0;
            return initial;
        }
        else return preset;
    }, [initialized]);


    //Css toggle for alternate header text transition w/ reduced motion preference
    useEffect(() => {
        let fadeInTimer: NodeJS.Timeout, 
            fadeOutTimer: NodeJS.Timeout;
        if (!reducedMotion || !initialized?.current) return;
        else {
            const headerElement = document.querySelector(`#headerElementText.${fadeCssClass}`),
                  header = headerElement as HTMLElement;
            if (!headerText?.includes(templateType)) {
                header.classList.add('hidden');
            }
            fadeOutTimer = setTimeout(() => {
                fadeInTimer = setTimeout(() => {
                    header.classList.remove('hidden');
                }, 100);
            }, 100);
            return () => {
                if (fadeInTimer) clearTimeout(fadeInTimer);
                if (fadeOutTimer) clearTimeout(fadeOutTimer)
            }
        }
    }, [initialized, templateType, headerText, reducedMotion]);


    //Css toggle for alternate section text transition w/ reduced motion preference
    useEffect(() => {
        let fadeInTimer: NodeJS.Timeout, 
            fadeOutTimer: NodeJS.Timeout;
        if (!reducedMotion || !initialized?.current) return;
        else {
            const sectionElement = document.querySelector(`#sectionElementText.${fadeCssClass}`),
                  section = sectionElement as HTMLElement;
            if (sectionText === initialSection && hasSearchResult) {
                section.classList.add('hidden');
            }
            fadeOutTimer = setTimeout(() => {
                fadeInTimer = setTimeout(() => {
                    section.classList.remove('hidden');
                }, 100);
            }, 100);
        }
        return () => {
            if (fadeInTimer) clearTimeout(fadeInTimer);
            if (fadeOutTimer) clearTimeout(fadeOutTimer)
        }
    }, [hasSearchResult, sectionText, reducedMotion]);
    

    //Header element JSX change if user prefers reduced motion
    const headerElement = useMemo(() => {
        const config = getSpringConfig();
        if (reducedMotion) return (
            <section id="headerElementText" className={fadeCssClass}>
                {headerText}&nbsp;&nbsp;<FontAwesomeIcon id="searchTemplatesIcon" icon={faMagnifyingGlass}/>
            </section>
        );
        else return (
            <TextTransition direction={"down"} 
                            delay={425} 
                            springConfig={config}>
                {headerText}&nbsp;&nbsp;<FontAwesomeIcon id="searchTemplatesIcon" icon={faMagnifyingGlass}/>
            </TextTransition>
        );
    }, [reducedMotion, headerText, getSpringConfig]);


    //Section element JSX change if user prefers reduced motion
    const sectionChangingTextElement = useMemo(() => {
        const config = getSpringConfig(presets.slow);
        if (reducedMotion) return (
            <section id="sectionElementText" className={fadeCssClass}>
                {sectionText}  {hasSearchResult && <FontAwesomeIcon id="viewResultsBelowIcon" icon={faChevronDoubleDown}/>}
            </section>
        );
        else return (
            <TextTransition direction={"down"} 
                            delay={750} 
                            springConfig={config}>
                {sectionText}  {hasSearchResult && <FontAwesomeIcon id="viewResultsBelowIcon" icon={faChevronDoubleDown}/>}
            </TextTransition>
        );
    }, [reducedMotion, sectionText, getSpringConfig, hasSearchResult]);

    const hasTemplateTypeSelected = useCallback(() => {
        return !(templateType === ExcelTemplateTypes.None);
    }, [templateType]);

    const updateSectionText = useCallback(() => {
        const text = `${ (hasTemplateTypeSelected()) ? !hasSearchResult && searchInProgress ? "Searching..."
                                                                                            : "Define your search criteria below." 
                                                                         : initialSection }`;
        if (text !== sectionText) {
            setSectionText(text);
        }
    }, [sectionText, hasTemplateTypeSelected, hasSearchResult, searchInProgress]);


    const updateHeaderText = useCallback(() => {
        const text = `Search ${ hasTemplateTypeSelected() ? templateType : "Template" } History`;
        if (headerText && !headerText.includes(text)) {
            setHeaderText(text);
        }
    }, [headerText, templateType, hasTemplateTypeSelected]);


    //Facilitates automatic resizing for the animated text transitions by 
    //wiping out the inline css and forcing it to regenerate on the next render
    const handleWindowResize = useCallback(() => 
    {
        const transitionElements = document.querySelectorAll('.text-transition');
        for (const element of transitionElements)
        {
            if (element != null) {
                element.setAttribute('style', "");
            }
        }
    }, []);

    //Handles adjustments on window resizing
    useEffect(() => {
        updateHeaderText();
        updateSectionText();
        handleWindowResize();
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [handleWindowResize, updateSectionText, updateHeaderText]);

    return (
        <>
            <h2 id="templateHistoryHeader" data-testid="templateHistoryHeaderTitle">
                <span>
                    {headerElement}
                </span>
            </h2>
            <div id="templateHistoryTextSection">
                <section data-testid="templateHistoryHeaderLowerText" className="lowerText">
                    You can get details about previously uploaded templates here.<br /><br />
                    {sectionChangingTextElement}
                </section>
            </div>
        </>
    )
}
