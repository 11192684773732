//import { LoginCallback } from "@okta/okta-react"
import { RootLayout, } from "pages"
import { PricingPortalPage, SettingsPage, TemplateHistoryPage } from "../../pages";
import { Route, Routes } from 'react-router-dom'
import { Suspense } from "react";
import LoadingSplash from "components/SecureRoute/LoadingSplash";

const suspenseWrapper = (element: JSX.Element) => {
    return (
        <Suspense fallback={<LoadingSplash />}>
            {element}
        </Suspense>
    )
}

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="" element={suspenseWrapper(<RootLayout />)}>
                <Route index element={suspenseWrapper(<PricingPortalPage/>)} />
                <Route path="history" index element={suspenseWrapper(<TemplateHistoryPage />)} />
                <Route path="settings" index element={suspenseWrapper(<SettingsPage/>)} />
            </Route>
        </Routes>
    )
}
