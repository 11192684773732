import React, { FC, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Route, RouteProps, Routes, useMatch } from 'react-router-dom';
import LoadingSplash from './LoadingSplash';

import { OnAuthRequiredFunction } from '@okta/okta-react/bundles/types/OktaContext';


export const SecureRoute: FC<{
  onAuthRequired?: OnAuthRequiredFunction;
  errorComponent?: React.ComponentType<{ error: Error }>;
} & RouteProps & React.HTMLAttributes<HTMLDivElement>> = ({
  onAuthRequired,
  errorComponent,
  ...routeProps
}) => {
    const isLoginPending = useRef(false)
    const { oktaAuth, authState, _onAuthRequired } = useOktaAuth();
    const path = routeProps.path ? routeProps.path : ""
    const match = useMatch(path)
    const [handleLoginError, setHandleLoginError] = React.useState<Error | null>(null);
    

    useEffect(() => {

      const updateAuth = async () => {

        if (isLoginPending.current) {
          return;
        }

        if (authState && !authState.isAuthenticated) {
          const originalUri = toRelativeUrl(window.location.href, window.location.origin);
          oktaAuth.setOriginalUri(originalUri);
          await oktaAuth.signInWithRedirect();

          isLoginPending.current = true;
        }

      }

      if (!match) {
        return;
      }

      if (!authState) {
        return;
      }

      if (authState.isAuthenticated) {
        isLoginPending.current = false;
        return;
      }

      if (!authState.isAuthenticated) {
        updateAuth().catch(err => {
          setHandleLoginError(err)
        })
      }

    }, [authState,
      oktaAuth,
      match,
      isLoginPending,
      _onAuthRequired,
      setHandleLoginError])

    if (handleLoginError && errorComponent) {
      const ErrorComponent = errorComponent;
      return <ErrorComponent error={handleLoginError} />
    }

    if (!authState || !authState?.isAuthenticated) {
      return (<LoadingSplash />);
    }

    return (
      <Routes>
        <Route {...routeProps} />
      </Routes>)
  }