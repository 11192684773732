import { OktaAuthOptions, AuthState, UserClaims, OktaAuth } from '@okta/okta-auth-js'

export const SCOPES: string[] = ['openid', 'profile', 'email', 'offline_access']
export const BASE_URL = 'https://dotfoods.okta.com'
export const CLIENT_ID = '0oa11834438xvvXyQ0x8' 
export const ISSUER = `${BASE_URL}/oauth2/default`
export const LOGIN_CALLBACK_URI = `/${process.env.REACT_APP_AUTHORIZATION_CALLBACK}/callback-internal`
export const LOGIN_REDIRECT_URI = `${window.location.origin}${process.env.PUBLIC_URL}${LOGIN_CALLBACK_URI}`
export const POST_LOGOUT_REDIRECT_URI = `${window.location.origin}${process.env.PUBLIC_URL}`

export const authConfig: OktaAuthOptions = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: LOGIN_REDIRECT_URI,
  scopes: SCOPES,
  pkce: true,
  transformAuthState: async (oktaAuth: OktaAuth, authState: AuthState): Promise<AuthState> => {
    if (!authState.isAuthenticated) {
      return authState
    }

    // extra requirement: user must have valid Okta SSO session if the user is online
    if (navigator.onLine) {
      const user: UserClaims | undefined = await oktaAuth.token.getUserInfo()
      authState.isAuthenticated = !!user
    }

    return authState
  },
} as OktaAuthOptions
