import { useReducedMotion, useSpring, animated, Interpolation, SpringValue } from '@react-spring/web';
import React, { forwardRef, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDown } from "@fortawesome/pro-duotone-svg-icons";
import { ExcelTemplateTypes } from 'enums';
import "./TemplateSelectIndicator.scss";

interface Props {
    selected: ExcelTemplateTypes,
    className?: string,
    scalar?: number
}

interface SpringProps {
    x: SpringValue<number>
}

interface AnimationProps {
    transform: Interpolation<number, string>
}

export default forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>
    (function TemplateSelectIndicator({selected, className = "", scalar = 1}: Props, ref) {
        const loadTime = 2250,
              loopTime = 2500;
        const reducedMotion = useReducedMotion();
        const arrowIconRef = useRef(null);
        const animationRefs = useRef({ animation: {} as AnimationProps, hasLoaded: false});

        const [loop] = useSpring(() => ({
            from: { x: 0 },
            to: { x: 1 },
            loop: true,
            delay: 10,
            config: {duration: loopTime}
        }));

        const [entry] = useSpring(() => ({
            from: { x: 0 },
            to: { x: 1 },
            delay: 0,
            config: {duration: loadTime}
        }));

        //Load-in animation => looping animation after timer
        useEffect(() => {
            animationRefs.current.animation = getAnimationProps(animationRefs.current.hasLoaded, entry, loop, scalar);
            let loadEffectTimer = setTimeout(() => {
                animationRefs.current.hasLoaded = true;
                animationRefs.current.animation = getAnimationProps(animationRefs.current.hasLoaded, entry, loop, scalar);
            }, loadTime);
            return () => clearTimeout(loadEffectTimer);
        }, [entry, loop, scalar]);

        //Fade out on template select
        useEffect(() => {
            if (selected !== ExcelTemplateTypes.None){
                const timer = setTimeout(() => {
                    if (arrowIconRef.current) {
                        const element = arrowIconRef.current as HTMLElement;
                        element.style.opacity = '0';
                        element.style.visibility = 'hidden';
                    }
                }, 50);
                return () => clearTimeout(timer);
            }
        }, [selected]);

        const innerElement = <FontAwesomeIcon ref={arrowIconRef}
                                              id="templateDropdownIndicatorIcon" 
                                              icon={faDown} />;

        return (!reducedMotion ? <animated.div ref={ref} 
                                            className={`${className} delayTransition`} 
                                            id="templateDropdownIndicator" 
                                            style={animationRefs.current.animation}>{innerElement}</animated.div>
                            : <div className={className} 
                                   id="templateDropdownIndicator">{innerElement}</div>
        );
    }
);


const getAnimationProps = (hasLoaded: boolean, load: SpringProps, loop: SpringProps, scalar: number) => { 
    let interpolation
    if (!hasLoaded) {
        interpolation = load.x.to({ range: [0, 0.2, 0.4, 0.6, 0.8, 0.95, 1],
                                    output: [-105, -95, -47, -20, -10, -2, 6].map(n => n * scalar) })
                              .to(y => `translateY(${y}px)`);
    } else {
        interpolation = loop.x.to({ range: [0, 0.2, 0.4, 0.6, 0.8, 1], 
                                    output: [-4, 2, 6, 6, 2, -4].map(n => n * scalar) })
                              .to(x => `translateY(${x}px)`);
    }
    return { transform: interpolation } as AnimationProps;
};
