import "./ErrorToggleButton.scss";
import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@progress/kendo-react-buttons";

type Props = {
    btnIcon: IconDefinition,
    disabled?: boolean
    onClick?: () => void
}

export default function ErrorToggleButton({btnIcon, onClick, disabled = false }: Props) {
    
    return (
        <Button disabled={disabled} className="errorToggleButton" onClick={onClick}>
            <div className="iconContainer">
                {<FontAwesomeIcon
                    className="errorIconSymbol iconMask"
                    icon={btnIcon}
                    size={'lg'}
                />}
                {<FontAwesomeIcon
                    className="errorIconSymbol"
                    icon={btnIcon}
                    size={'lg'}
                />}
            </div>
        </Button>
    )
}