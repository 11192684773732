import { ExcelPropertyInfo, ExcelViewerErrorData } from 'types';
import { ClientValidationErrorType, ClientValidationError } from 'errors';
import { commonRegex } from 'utils';

export const useExcelValidationHelpers = () => {
    const regex = { hasSpaces: commonRegex.hasSpaces,
                    startsWithVowel: /^[aeiou]/i };
    
    //Logs error for omitted required properties
    const checkRequiredField = <THeaders>(columnHeaderEnum: THeaders, props: ExcelPropertyInfo) => {
        const { propName, value, dataRow, log, errorKey } = props,
                propNameKey = propName as keyof THeaders,
                propColumnName = `column "${columnHeaderEnum[propNameKey]}"`; 
        if(!value && (typeof log !== "undefined")) {
            const errText = `Empty value for ${propColumnName}, this field is required.`;
            log.push(new ExcelViewerErrorData(dataRow, errorKey ?? propName ?? "", [errText]));
            return false;
        }
        return true;
    };


    //Throws an error to abort validation if Excel data appears to have incorrect column headers, or if data is empty
    const checkExcelHeaders = <TemplateModel>(excel: TemplateModel[] | null, 
                                              skipNumHeaders: number, 
                                              additionalChecks?: () => void) => 
    {
        if (!excel || excel.length === 0) {
            throw new ClientValidationError(ClientValidationErrorType.NoData, 
                                            "No Excel data to validate!", 
                                            null);
        }
        if (skipNumHeaders < 0) {
            throw new ClientValidationError(ClientValidationErrorType.BadExcelHeaders, 
                                            "Validation service: skipNumHeaders must be a positive number.", 
                                            null);
        }
        if (excel.length <= skipNumHeaders) {
            throw new ClientValidationError(ClientValidationErrorType.NoData, 
                                            "Excel data appears to contain no data rows, only headers. ",
                                            null);
        }
        if (additionalChecks) additionalChecks();
    };

    //Parses an int from the value of propinfo, logs an error and returns 0 on failure.
    const tryParseInt = <THeaders>(columnHeaderEnum: THeaders, propInfo: ExcelPropertyInfo) => {
        const { propName, value, dataRow, errorKey, log } = propInfo;
        const parsed = parseInt(value ?? ""),
              success = !(Number.isNaN(parsed)),
              propNameKey = propName as keyof THeaders,
              propColumnName = `column "${columnHeaderEnum[propNameKey]}"`; 
        if(!success && (typeof log !== "undefined")) {
            const errText = `Unable to parse integer "${value}"` + 
                            `${columnHeaderEnum[propNameKey] ? ` in ${propColumnName}` : ""}.`;
            log.push(new ExcelViewerErrorData(dataRow, errorKey ?? propName ?? "", [errText]));
        }
        return success ? parsed : 0;
    };

    //Strips spaces and lower case letters out of Excel template property names
    const standardizePropertyNames = <TExcelTemplate>(rawExcel: any[]): TExcelTemplate[] => {
        const matchPattern: RegExp = regex.hasSpaces,
              newExcelArray: TExcelTemplate[] = [];
        for(const row of rawExcel) {
            const removeSpaces = Object.entries(row)
                                       .map(([key, value]) => {
                                        //A quirk with JavaScript's regex engine is that  
                                        //we have to reset lastIndex manually here
                                            matchPattern.lastIndex = 0;
                                            return [key.replace(matchPattern, '').toUpperCase(), value];
                                       }),
                  standardPropNames = Object.fromEntries(removeSpaces);
            newExcelArray.push(standardPropNames as TExcelTemplate);
        }
        return newExcelArray;
    };

    const trimValuesLeadingAndTrailingWhitespace = <TExcelTemplate>(rawExcel: any[]): TExcelTemplate[] => {
        const result = rawExcel.map(row => {
            const kvps = Object.entries(row)
                               .map(([key, value]) => {
                                //This check is likely redundant b/c we import everything as strings, but just in case
                                   if (value && (typeof value === "string" || value instanceof String)) {
                                       return [key, value.trim()];
                                   }
                                   else return [key, value];
                               });
            return Object.fromEntries(kvps) as TExcelTemplate
        });
        return result;
    }

    //For error message, contextual "a" or "an"
    const grammarHelper = (str: string) => 
        regex.startsWithVowel.test(str.trim()) ? `an ${str}` : `a ${str}`;


    return {
        checkRequiredField,
        checkExcelHeaders,
        tryParseInt,
        standardizePropertyNames,
        trimValuesLeadingAndTrailingWhitespace,
        grammarHelper
    };
}
