import React, { useState, useCallback, useEffect } from 'react';

interface CellProps {
    value: string;
    rowIndex: number;
    columnKey: string;
    onChange: (rowIndex: number, columnKey: string, value: string) => void;
    isHeader: boolean;
    hasError: boolean;
    mouseoverText: string;
}

const Cell: React.FC<CellProps> = React.memo(({ value, rowIndex, columnKey, onChange, isHeader, hasError, mouseoverText }) => {
    const [cellValue, setCellValue] = useState(value);

    useEffect(() => {
        setCellValue(value);
    }, [value]);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setCellValue(newValue);
        onChange(rowIndex, columnKey, newValue);
    }, [rowIndex, columnKey, onChange]);

    //console.log("Cell Being Re rendered is - Row:" + rowIndex + "| ColumnKey:" + columnKey + "| Value:" + value);

    return (
        <td
            data-row={rowIndex + 2}
            data-column={columnKey}
            title={mouseoverText ? mouseoverText : undefined}
            className={`${hasError ? "hasError" : ""}`}
        >
            {isHeader ? (
                value
            ) : (
                <input type="text" value={cellValue} onChange={handleInputChange} />
            )}
        </td>
    );
});

export default Cell;
