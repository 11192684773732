import "./SettingsPage.scss"
import { Helmet } from 'react-helmet-async';


export default function SettingsPage() {  
    return (
        <>
            <Helmet>
                <title>Pricing Portal | Template Drop</title>
            </Helmet>
            <div className="placeholderDiv">
                <h1 className="placeholder">(Settings Placeholder)</h1>
            </div>
        </>
    );
}