import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { filterClearIcon } from "@progress/kendo-svg-icons";
import { SvgIcon } from "@progress/kendo-react-common";
import "./TemplateHistoryDateRangeFilter.scss";

interface DateFilterProps {
    minDate: Date | null,
    maxDate: Date | null,
    onFilterClear: () => void,
    onFilterChange: (date: Date | null, operator: "lt" | "gt") => void
}

export default function TemplateHistoryDateRangeFilter(props: DateFilterProps) {
    const { minDate, maxDate, onFilterClear, onFilterChange } = props;

    return (
        <div className="dateFilter k-filtercell">
            <DatePicker format={"MMMM dd, yyyy"}
                        value={minDate}
                        onChange={(e) => onFilterChange(e.target.value, "gt" )}/>
            <DatePicker format={"MMMM dd, yyyy"}
                        value={maxDate}
                        onChange={(e) => onFilterChange(e.target.value, "lt")} />
            <Button title="Clear"
                    className="clearButton"
                    disabled={!(minDate || maxDate)}
                    onClick={() => onFilterClear()}>
                <SvgIcon icon={filterClearIcon} />
            </Button>
      </div>
    );
}
