export default function DotLogo() {
    return(
        <>  
            <img className="dot-logo" 
                src={`${process.env.PUBLIC_URL}/Pricing_manager_w_Logo.svg`}
                alt="Dot Foods"
                >
            </img> 
        </>
    );
}