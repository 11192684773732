import './TemplateValidationSuccess.scss';
import { faCheckDouble } from '@fortawesome/pro-duotone-svg-icons';
import { useEffect, useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    render: boolean,
    fileName: string,
    dismissCallback: () => void 
}

export default function TemplateValidationSuccess({render, fileName, dismissCallback}: Props) {
    const [shouldDisplay, setShouldDisplay] = useState<boolean>(true),
          [pageHasLoaded, setPageHasLoaded] = useState<boolean>(false);

    
    const toggleAfterDelay = useCallback(() => {
        setTimeout(() => {
            setShouldDisplay(render);
        }, 200);
    }, [render]);

    //Slightly delays the transition in/out css effect
    useEffect(() => {
        toggleAfterDelay();
    }, [render, toggleAfterDelay]);

    //Prevents panel from being visible immediately on page load, before hidden class is set
    useEffect(() => {
        setTimeout(() => {
            setPageHasLoaded(true);
        }, 500);
    }, []);

    // Auto-hide success panel after a specified time
    useEffect(() => {
        if (shouldDisplay) {
            const timer = setTimeout(() => {
                setShouldDisplay(false); // Hide the panel after 3 seconds
            }, 3000);
            return () => clearTimeout(timer); 
        }
    }, [shouldDisplay]);

    const getSuccessText = () => {
        const predicate = "has been submitted successfully.";
        if (fileName) {
            const fileCharArray = fileName.split(''),
                  longFileName = fileCharArray.length > 33,
                  displayName = longFileName ? `File "${fileCharArray.slice(0, 30).join('')}..." ${predicate}`
                                             : `File "${fileName}" ${predicate}`;
            return displayName;
        } else {
            return `Your template ${predicate}`;
        }
    }

    return (
        <>
        { pageHasLoaded ? 
            (<div id="validationSuccessPanel" className={`${shouldDisplay ? "" : "hidden"}`} data-testid="validationSuccessPanel">
                    <div className="iconContainer">
                        <p>{getSuccessText()}</p>
                        <div className="successIcon">
                            <FontAwesomeIcon icon={faCheckDouble}></FontAwesomeIcon>
                        </div>
                        {/*<TemplateButton text="" btnIcon={faXmark} onClick={dismissCallback}></TemplateButton>*/}
                    </div>
            </div>) : (<></>)
        }
        </>
    );
}
