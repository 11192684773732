import './Footer.scss';
import { DotPrivacyTerms, DotCopyright } from 'components';
import { NavLink } from 'react-router-dom';
import BrandPromise from 'components/BrandPromise';

export default function Footer() {
    return(
        <footer className='footer'>
            <div className="footer-links">
                <div className="logoContainer">
                    <NavLink to="" className="dot-logo-footer">
                        <BrandPromise />
                    </NavLink>
                </div>

                <div className='linksContainer'>
                    <div className="footer-column">
                        <h3>Support</h3>
                        <ul>
                            <li><a href="">Your Dot Team</a></li>
                            <li><a href="">Contact Dot Foods</a></li>
                            <li><a href="">Help Center</a></li>
                            <li><a href="">FAQ's</a></li>
                        </ul>
                    </div>
                    <div className="footer-column">
                        <h3>Supplier Resources</h3>
                        <ul>
                            <li><a href="">Partner Dashboard</a></li>
                            <li><a href="">Marketing Services</a></li>
                        </ul>
                    </div>
                </div>
                
            </div>
            <div className="footerBottom">
                <hr className='rowDivider' />
                <div className="bottomLinks">
                    <div className="footer-left">
                        <DotPrivacyTerms />
                    </div>
                    <div className="footer-right">
                        <DotCopyright />
                    </div>
                </div>
            </div>
        </footer>
    );
}