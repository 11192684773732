import "./ViewAllButton.scss"
import { Button } from "@progress/kendo-react-buttons"

export default function ViewAllButton() {
    const clickHandler = ()=>{
        window.location.href = `${process.env.PUBLIC_URL}/history`;
    }
    return (
        <Button className="viewAllButton" onClick={clickHandler}>
            <span className="buttonText">VIEW ALL</span>
        </Button>
    )
}