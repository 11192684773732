//Mapping display names to validation property names
export enum FloatItemPricingExcelColumnHeaders {
    customerNumber = "Customer Number",
    customerShipto = "Customer Shipto",
    name = "Customer Name",
    itemNumber = "Item Number",
    description = "Item Description",
    manufacturer = "Manufacturer Number",
    contractNumber = "Contract Number",
    contractName = "Contract Name",
    price = "Price",
    uom = "Unit of Measure",
    beginDate = "Begin Delivery Date",
    endDate = "End Delivery Date",
    employee = "Employee Number",
    jobType = "Job Type",
    supplierResponsible = "Supplier Responsible",
    comments = "Comments",
    promoFlag = "Promo Flag",
    nationalAccount = "National Account",
    floatType = "Float Type",
    adjust = "Adj",
    autoAdjust = "Auto Adj",
    adjustType = "Adj Type",
    adjustAmount = "Adj Amount",
    adjustPercentage = "Adj Percentage"
}

//Mapping raw excel property names to validation model property names
export enum FloatItemTemplateErrorKeys {
    customerNumber = "CUSTOMERNUMBER",
    customerShipto = "SHIPTO",
    name = "NAME",
    itemNumber = "ITEMNUMBER",
    description = "DESCRIPTION",
    manufacturer = "MANUFACTURER",
    contractNumber = "CONTRACTNUMBER",
    contractName = "CONTRACTNAME",
    price = "PRICE",
    uom = "UOM",
    beginDate = "BEGINDATE",
    endDate = "ENDDATE",
    employee = "EMPLOYEE",
    jobType = "JOBTYPE",
    supplierResponsible = "SUPPLIERRESPONSIBLE",
    comments = "COMMENTS",
    promoFlag = "PROMOFLAG",
    nationalAccount = "NATIONALACCT",
    floatType = "FLOATTYPE",
    adjust = "ADJY/N",
    autoAdjust = "AUTOADJY/N",
    adjustType = "ADJTYPE",
    adjustAmount = "ADJAMT",
    adjustPercentage = "ADJ%"
}

//Mapping raw excel property names to request/response property names
export enum FloatItemTemplateApiResponseKeys {
    CUSTOMERNUMBER = "CUSTOMERNUMBER",
    CUSTOMERSHIPTONUMBER = "SHIPTO",
    CUSTOMERNAME = "NAME",
    ITEMNUMBER = "ITEMNUMBER",
    ITEMDESCRIPTION = "DESCRIPTION",
    MANUFACTURERNUMBER = "MANUFACTURER",
    CONTRACTNUMBER = "CONTRACTNUMBER",
    CONTRACTDESCRIPTION = "CONTRACTNAME",
    PRICEAMOUNT = "PRICE",
    PRICEUNITOFMEASURE = "UOM",
    PRICEBEGINDATE = "BEGINDATE",
    PRICEENDDATE = "ENDDATE",
    EMPLOYEENUMBER = "EMPLOYEE",
    EMPLYEENUMBER = "EMPLYEE", //Misspelled in original template
    JOBTYPE = "JOBTYPE",
    SUPPLIERINDICATOR = "SUPPLIERRESPONSIBLE",
    COMMENT = "COMMENTS",
    PROMOFLAG = "PROMOFLAG",
    NATIONALACCT = "NATIONALACCOUNT", //Deprecated field, ignored
    FLOATTYPE = "FLOATTYPE", //Deprecated field, ignored 
    ADJUSTMENTFLAG = "ADJY/N",
    AUTOFLAG = "AUTOADJY/N",
    ADJUSTMENTTYPE = "ADJTYPE",
    ADJUSTMENTAMOUNT = "ADJAMT",
    ADJUSTMENTPERCENT = "ADJ%"
}
