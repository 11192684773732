import TemplateButton from 'components/Buttons/TemplateButton';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useInOutTransition } from 'hooks';
import { useMemo } from 'react';
import { TextExpandPanelProps } from 'types/TemplateHistory/TextExpandPanelProps';
import './TemplateHistoryTextExpandPanel.scss';

interface ExtraProps {
    dismissCallback: () => void
}

export default function TemplateHistoryTextExpandPanel(props: TextExpandPanelProps & ExtraProps) {
    const { shouldRender, dismissCallback, content, dataItemDetails } = props,
          { isActive, isNotTransitionedOut } = useInOutTransition(shouldRender),
          titleText = dataItemDetails.fieldDisplayName ?? "Details",
          text = useMemo(() => {
            return dataItemDetails.formatter ? dataItemDetails.formatter(content) 
                                             : content;
          }, [dataItemDetails, content]);

    return (isNotTransitionedOut && (
        <>
            <div className={`historyTextExpandPanel ${shouldRender && isActive ? "" : "hidden"}`} 
                 data-testid="historyTextExpandPanel">
                <p>{titleText}</p>
                <div>
                    <pre>{text}</pre>
                </div>
                <TemplateButton text="Close" btnIcon={faXmark} onClick={dismissCallback}></TemplateButton>
            </div>
        </>
    ));
}
