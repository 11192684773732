import '@progress/kendo-theme-fluent/dist/all.scss';
import './App.scss';
import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import AppRoutes from 'components/Routes/AppRoutes';
import {
	Route,
	Routes,
} from 'react-router-dom';
import { LoginCallback, Security } from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';
import { authConfig } from 'utils';
import { SecureRoute } from 'components/SecureRoute/SecureRoute';
import { LogoutPage } from 'components';



const oktaAuth = new OktaAuth(authConfig)

function App() {
	const useRestoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
		console.log(originalUri, ", ", window.location.origin)
		window.location.href=`${window.location.origin}${originalUri ?? ''}`
	}
	//console.log(oktaAuth);

	useEffect(() => {
		return () => {
			oktaAuth.options.restoreOriginalUri = undefined
		}
	}, [])

	return (
		<>
			<Security
				oktaAuth={oktaAuth}
				onAuthRequired={() => oktaAuth.signInWithRedirect()}
				restoreOriginalUri={useRestoreOriginalUri}
			>
				<HelmetProvider>
					<Routes>
						<Route path={`${process.env.REACT_APP_AUTHORIZATION_CALLBACK}/callback-internal`} element={<LoginCallback />} />
						<Route path="*" element={<SecureRoute path="*" element={<AppRoutes />}></SecureRoute>} />
						<Route path="logout" element={<LogoutPage />} />
					</Routes>
				</HelmetProvider>
			</Security>
		</>
	);
}

export default App;
