import { DateDetails } from "types";
import { format } from "date-fns";

export const dateFormatter = {
    YYYYMMDD: (date: DateDetails) => {
        const { year, month, day } = date;
        const yearStr = standardizeYearString(year);
        return yearStr + 
               `${month < 10 ? `0${month}` : month}` +
               `${day < 10 ? `0${day}` : day}`;
    },
    MMDDYYYY: (date: DateDetails) => {
        const { year, month, day } = date;
        const yearStr = standardizeYearString(year);
        return `${month < 10 ? `0${month}` : month}` +
               `${day < 10 ? `0${day}` : day}` + 
               yearStr;
    },
    CYYMMDD: (date: DateDetails) => {
        const { year, month, day } = date;
        const yearStr = standardizeYearString(year),
              yearSuffix = yearStr.slice(2),
              yearPrefix = parseInt(yearStr.slice(0, 2)),
              century = yearPrefix - 19;
        if (century < 0) throw new Error(`Error converting year ${yearStr} to CYY format, result was negative.`);
        else return `${century}${yearSuffix}` +
                    `${month < 10 ? `0${month}` : month}` +
                    `${day < 10 ? `0${day}` : day}`;
    },
    /* Formats the date presented in a component as "Month-name, day, 4 digit year". For example,
    2023-01-13 will be displayed as "January 13th, 2023." */
    DisplayDate: (date: Date) => format(date, "MMMM dd, Y")
};

const standardizeYearString = (year: number) => {
    let yearStr = year.toString();
    const yearLength = yearStr.length;
    if (yearLength < 4) {
        let leadingZeroes = 4 - yearLength;
        do { 
            yearStr = `${0}${yearStr}`;
            leadingZeroes++; 
        } while (leadingZeroes < 4);
    }
    return yearStr;
};
